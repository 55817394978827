import { axiosInstance } from '../../utils/axios';
import { IAssessmentLinkResponse } from './data';

export const createInvitationLink = async (
  accessToken: string,
  {
    test_id,
    company_id,
    requester_id,
    job_title,
    expires_at,
    max_applicants,
    issues_list,
  }: {
    test_id: number;
    company_id: number;
    requester_id: number;
    job_title: string;
    expires_at?: string;
    max_applicants?: number;
    issues_list?: string;
  }
): Promise<IAssessmentLinkResponse> => {
  const formData = new FormData();
  formData.append('test_id', test_id.toString());
  formData.append('company_id', company_id.toString());
  formData.append('requester_id', requester_id.toString());
  formData.append('job_title', job_title);

  if (max_applicants) {
    formData.append('max_applicants', max_applicants.toString());
  }

  if (expires_at) {
    formData.append('expires_at', expires_at.toString());
  }
  if (issues_list) {
    formData.append('issues_list', issues_list);
  }

  const { data } = await axiosInstance.post(
    '/api/storage-commons/invitation-links',
    formData,
    {
      headers: { Authorization: `Bearer ${accessToken}` },
    }
  );

  return data;
};

export const createCandidateForAssessment = async ({
  email,
  password,
  userMetadata,
}: {
  email: string;
  password: string;
  userMetadata: Record<string, unknown>;
}): Promise<any> => {
  const { data } = await axiosInstance.post('/api/admin/candidates', {
    email: email,
    password: password,
    user_metadata: userMetadata,
    verify_email: false,
    connection: 'Username-Password-Authentication',
  });
  return data;
};
