import archiveOutlined from '@iconify/icons-eva/archive-outline';
import flashFillEva from '@iconify/icons-eva/flash-fill';
import peopleFillEva from '@iconify/icons-eva/people-fill';
import settingsFill from '@iconify/icons-fa6-solid/gears';
import layersFill from '@iconify/icons-fa6-solid/layer-group';
import graduate from '@iconify/icons-fa6-solid/graduation-cap';
import paperFill from '@iconify/icons-fa6-solid/sheet-plastic';
import peopleFill from '@iconify/icons-fa6-solid/users';

import { Icon } from '@iconify/react';

import { User } from '@auth0/auth0-react';
import { INavItemProps } from '../../components/Sidebar/NavItem';
import { isCandidate, isCompany, isPersonal } from '../../utils/auth';

export interface ILayoutProps {
  isSidebarOpen: boolean;
  menuItems: INavItemProps[];
  handleSidebarOpen: () => void;
  handleSidebarClose: () => void;
}

export const COMPANY_MENU_ITEMS = [
  {
    href: '/company/dashboard',
    icon: <Icon icon={layersFill} />,
    title: 'Dashboard',
  },
  {
    href: '/company/invitation-links',
    icon: <Icon icon="fa6-solid:link" />,
    title: 'Invitation Links',
  },
  {
    href: '/company/candidates',
    icon: <Icon icon={peopleFill} />,
    title: 'Candidates',
  },
  {
    href: '/company/tests-catalogue',
    icon: <Icon icon={paperFill} />,
    title: 'Tests Catalogue',
  },
  {
    href: '/company/test-insights',
    icon: <Icon icon={'fluent-mdl2:insights'} />,
    title: 'Test Insights',
  },
  {
    href: '/company/settings',
    icon: <Icon icon={settingsFill} />,
    title: 'Settings',
  },
];

export const PERSONAL_MENU_ITEMS = [
  {
    href: '/company/learning-paths',
    icon: <Icon icon={graduate} />,
    title: 'Learning Paths',
  },
  {
    href: '/company/tests-catalogue',
    icon: <Icon icon={paperFill} />,
    title: 'Tests Catalogue',
  },
  {
    href: '/company/test-insights',
    icon: <Icon icon={'fluent-mdl2:insights'} />,
    title: 'Test Insights',
  },
  {
    href: '/company/tests-history',
    icon: <Icon icon={archiveOutlined} />,
    title: 'Tests History',
  },
  {
    href: '/company/settings',
    icon: <Icon icon={settingsFill} />,
    title: 'Settings',
  },
];

export const CANDIDATE_MENU_ITEMS = [
  {
    href: '/candidate/upgrade-to-personal',
    icon: <Icon icon={flashFillEva} />,
    title: 'Activate Personal',
  },
  {
    href: '/candidate/test-invites',
    icon: <Icon icon={peopleFillEva} />,
    title: 'Test Invites',
  },
];

export const getMenuItems = (user: User | undefined) => {
  if (isCompany(user)) {
    return COMPANY_MENU_ITEMS;
  } else if (isCandidate(user)) {
    return CANDIDATE_MENU_ITEMS;
  } else if (isPersonal(user)) {
    return PERSONAL_MENU_ITEMS;
  } else {
    return [];
  }
};

export function onboardingToutIconStyle(showOnboardingTour: boolean) {
  const style = {
    position: 'relative',
    animation: showOnboardingTour
      ? 'pulse 2s infinite, blink 1.75s infinite'
      : 'none',
    '@keyframes blink': {
      '0%': { opacity: 1 },
      '50%': { opacity: 0.5 },
      '100%': { opacity: 1 },
    },
    '@keyframes pulse': {
      '0%': {
        boxShadow: '0 0 0 0 rgba(126, 87, 194, 0.7)',
      },
      '70%': {
        boxShadow: '0 0 25px 20px rgba(126, 87, 194, 0)',
      },
      '100%': {
        boxShadow: '0 0 0 0 rgba(126, 87, 194, 0)',
      },
    },
    ...(showOnboardingTour && {
      border: '2px solid rgba(126, 87, 194, 0.7)',
      borderRadius: '50%',
    }),
  };
  return style;
}
