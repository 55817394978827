import { User, useAuth0 } from '@auth0/auth0-react';
import { yupResolver } from '@hookform/resolvers/yup';
import infoIcon from '@iconify/icons-eva/info-outline';
import { Icon } from '@iconify/react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useEffect, useMemo } from 'react';
import { Controller, FieldValues, useForm, useWatch } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useLocation } from 'react-router-dom';
import * as yup from 'yup';
import { useGetPrivateTestsQuery } from '../../../@generated/facadeClient';
import { SubscriptionAlert } from '../../../components/SubscriptionAlert';
import { VALID_LINKEDIN_REG_EXP } from '../../../constants/regexp';
import { useBilling } from '../../../hooks/useBilling';
import {
  getCompanyId,
  getCompanyName,
  getUserMetaData,
} from '../../../utils/auth';
import { calculateProblemsThreshold } from '../../../utils/common';
import TestDescription from '../TestDescription';
import { createUser } from '../utils';
interface ICreateDialogProps {
  handleClose(): void;
  onSuccess(user: User): void;
  onError(error: string): void;
  propTestId?: number | null;
}

const schema = yup
  .object({
    email: yup
      .string()
      .email('Please enter a valid email address.')
      .required('Please enter email address.'),
    fullName: yup.string().required('Please enter full name.'),
    linkedIn: yup
      .string()
      .matches(VALID_LINKEDIN_REG_EXP, {
        excludeEmptyString: true,
        message: 'Please enter a valid LinkedIn profile url.',
      })
      .nullable()
      .optional(),
    testId: yup.string().required('Please select test.'),
    issuesPerTest: yup.array().of(yup.object()),
  })
  .required();

export default function CreateDialog({
  handleClose,
  onSuccess,
  onError,
  propTestId,
}: ICreateDialogProps) {
  const { getAccessTokenSilently, user } = useAuth0();
  const { hasActiveFreeTrial, canAccessProFeatures } = useBilling();
  const companyId = getCompanyId(user);

  const { data: { tests } = { tests: [] } } = useGetPrivateTestsQuery({
    variables: { company_id: companyId },
  });

  const { pathname } = useLocation();
  const s = pathname.split('/');
  const test = s[s.length - 2];
  const isTestCatalogue = test === 'test-details';
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    resetField,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      fullName: '',
      testId: isTestCatalogue ? propTestId?.toString() : '',
      linkedIn: null,
      issuesPerTest: undefined,
    },
  });

  const testId = useWatch({ control, name: 'testId' });
  useEffect(() => {
    resetField('issuesPerTest');
  }, [testId, resetField]);

  const selectedTest = useMemo(
    () => tests?.find(({ brokee_id }) => brokee_id.toString() === testId),
    [tests, testId]
  );
  const { maxThreshold, minThreshold } = calculateProblemsThreshold(
    selectedTest?.issues_per_test || 0
  );
  const { isLoading, mutate } = useMutation(
    async ({
      email,
      fullName,
      testId,
      linkedIn,
      issuesPerTest = [],
    }: FieldValues) => {
      const lowercasedEmail = email.toLowerCase();
      const token = await getAccessTokenSilently();
      return await createUser(token, {
        email: lowercasedEmail,
        name: fullName,
        appMetadata: {
          test_id: testId,
          requester: user?.name,
          company_id: getUserMetaData(user)?.company_id,
          requester_id: getUserMetaData(user)?.local_user_id,
          company_name: getCompanyName(user),
          issues_per_test: selectedTest?.issues_per_test ?? 0,
          available_issues: selectedTest?.test_issues.length ?? 0,
          issues_list: issuesPerTest.map((test: any) => test?.issue_id),
        },
        userMetadata: {
          linkedIn,
          name: fullName,
        },
      });
    },
    {
      onSuccess: (data) => {
        onSuccess(data);
      },
      onError: (error: AxiosError) => {
        onError(
          (error?.response?.data as { message: string })?.message ??
            `Something went wrong. Please try again.`
        );
      },
    }
  );

  const onSubmit = async (data: FieldValues) => {
    if (selectedTest?.dynamic_issues && data.issuesPerTest) {
      if (data.issuesPerTest.length === 0) {
        mutate(data);
        return;
      }

      if (
        data.issuesPerTest.length < minThreshold ||
        data.issuesPerTest.length > maxThreshold
      ) {
        setError('issuesPerTest', {
          type: 'custom',
          message: `Select a number of problems between ${minThreshold} and ${maxThreshold} to meet the test requirements.`,
        });
        return;
      }
    }
    mutate(data);
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      fullWidth
      maxWidth="lg"
      scroll="body"
      id="candidate-create-dialog"
    >
      <DialogTitle>Create new candidate</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogContent id="candidate-create-dialog">
          <Grid container spacing={2}>
            {!canAccessProFeatures() && (
              <Grid item xs={12}>
                <SubscriptionAlert text="Unlock advanced tests." />
              </Grid>
            )}
            <Grid item xs={12} sm={12} md={7}>
              <DialogContentText>
                Please enter the candidate's full name, email address and select
                a test to pass.
              </DialogContentText>
              <Box id="candidate-create-form">
                <FormControl
                  fullWidth
                  required
                  margin="normal"
                  id="candidate-create-form-email"
                  error={!!errors.email}
                >
                  <TextField
                    {...register('email')}
                    id="email"
                    autoFocus
                    required
                    label="Email Address"
                    type="email"
                    variant="filled"
                    autoComplete="off"
                    aria-describedby="email-helper"
                  />
                  <FormHelperText id="email-helper">
                    {(errors.email?.message ?? '') as string}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  required
                  margin="normal"
                  id="candidate-create-form-name"
                  error={!!errors.fullName}
                >
                  <TextField
                    {...register('fullName')}
                    id="name"
                    autoFocus
                    required
                    label="Full Name"
                    variant="filled"
                    autoComplete="off"
                    aria-describedby="fullName-helper"
                  />
                  <FormHelperText id="fullName-helper">
                    {(errors.fullName?.message ?? '') as string}
                  </FormHelperText>
                </FormControl>
                <FormControl
                  fullWidth
                  margin="normal"
                  error={!!errors.linkedIn}
                  id="candidate-create-form-linkedin"
                >
                  <TextField
                    {...register('linkedIn')}
                    id="linkedIn"
                    label="LinkedIn Profile URL (Optional)"
                    variant="filled"
                    aria-describedby="linkedIn-helper"
                  />
                  <FormHelperText id="linkedIn-helper">
                    {(errors.linkedIn?.message ?? '') as string}
                  </FormHelperText>
                </FormControl>
              </Box>

              <FormControl
                fullWidth
                required
                margin="normal"
                id="candidate-create-form-test"
                error={!!errors.testId}
              >
                <TextField
                  {...register('testId')}
                  select
                  required
                  label="Test"
                  disabled={isTestCatalogue}
                  aria-describedby="test-helper"
                  SelectProps={{
                    native: true,
                  }}
                >
                  <option value={testId}> {selectedTest?.name}</option>
                  {tests
                    .filter(({ requires_payment }) =>
                      hasActiveFreeTrial() ? !requires_payment : true
                    )
                    .map(({ brokee_id, name, dynamic_issues }) => (
                      <option key={`test${brokee_id}`} value={brokee_id}>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          {name} {dynamic_issues && '🎲'}
                        </Box>
                      </option>
                    ))}
                </TextField>
                <FormHelperText id="test-helper">
                  {(errors.testId?.message ?? '') as string}
                </FormHelperText>
              </FormControl>
              {selectedTest?.dynamic_issues && (
                <FormControl
                  fullWidth
                  required
                  margin="normal"
                  error={!!errors.issuesPerTest}
                >
                  <Controller
                    name="issuesPerTest"
                    control={control}
                    render={({ field }) => (
                      <Tooltip
                        title={
                          !canAccessProFeatures()
                            ? 'Subscribe to be able to select a set of problems'
                            : ''
                        }
                        placement="top"
                      >
                        <Autocomplete
                          {...field}
                          multiple
                          disabled={!canAccessProFeatures()}
                          options={selectedTest?.test_issues ?? []}
                          disableCloseOnSelect
                          onChange={(_, value) => {
                            clearErrors('issuesPerTest');
                            field.onChange(value);
                          }}
                          value={field.value ?? []}
                          getOptionLabel={(option) => option.title ?? ''}
                          isOptionEqualToValue={(option, value) =>
                            option.issue_id === value.issue_id
                          }
                          size="medium"
                          renderOption={(props, option, { selected }) => (
                            <li {...props}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  justifyItems: 'space-between',
                                  alignItems: 'center',
                                  width: '100%',
                                }}
                              >
                                <Box>
                                  <Checkbox
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                  />
                                  {option.title}
                                </Box>
                                <Box>
                                  <Tooltip title={option.explanation ?? ''}>
                                    <Icon icon={infoIcon} />
                                  </Tooltip>
                                </Box>
                              </Box>
                            </li>
                          )}
                          renderInput={(params) => (
                            <TextField {...params} label="Set of problems" />
                          )}
                          componentsProps={{
                            popper: {
                              modifiers: [
                                {
                                  name: 'offset',
                                  options: {
                                    offset: [0, 10],
                                  },
                                },
                              ],
                            },
                          }}
                        />
                      </Tooltip>
                    )}
                  />
                  <FormHelperText id="issuesPerTest-helper" error>
                    {(errors.issuesPerTest?.message ?? '') as string}
                  </FormHelperText>
                  <Typography variant="caption">
                    {`By default, the test will generate a random set of ${selectedTest.issues_per_test} problems. 
                      If you require specific issues to be included, you have the 
                      option to select a custom set of problems.`}
                  </Typography>
                </FormControl>
              )}
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              {selectedTest && <TestDescription test={selectedTest} />}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            id="candidate-close-invite-dialog"
            onClick={handleClose}
            variant="text"
          >
            Cancel
          </Button>
          <Button
            id="candidate-submit-invite"
            type="submit"
            variant="contained"
            disabled={isLoading}
            style={{ width: '165px' }}
          >
            {isLoading ? (
              <>
                <CircularProgress
                  size={20}
                  thickness={1}
                  style={{ marginRight: 10 }}
                />
                Creating
              </>
            ) : (
              'Create & Continue'
            )}
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
