import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { ETourNames } from './tour';
import { Step } from 'react-joyride';

type TourContextType = {
  run: boolean;
  currentTour: string | null;
  triggerDialog: boolean;
  isTourInitialized: boolean;
  stepIndex: number;
  steps: Record<string, Step[]>;
  startTour: (tourId: string, steps: Step[]) => void;
  stopTour: () => void;
  setRun: (run: boolean) => void;
  setTourInitialized: (initialized: boolean) => void;
  setStepIndex: (index: number) => void;
  setTriggerDialog: (state: boolean) => void;
};

const TourContext = createContext<TourContextType | undefined>(undefined);
type TourProviderProps = {
  children: ReactNode;
};
export const TourProvider: React.FC<TourProviderProps> = ({ children }) => {
  const [run, setRun] = useState(false);
  const [isTourInitialized, setTourInitialized] = useState(false);
  const [currentTour, setCurrentTour] = useState<string | null>(null);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState<Record<string, Step[]>>({});

  const [triggerDialog, setTriggerDialog] = useState(false);

  const startTour = useCallback((tourId: string, newSteps: Step[]) => {
    setSteps((prev) => ({ ...prev, [tourId]: newSteps }));
    setCurrentTour(tourId);
    setStepIndex(0);
    setRun(true);
  }, []);

  const stopTour = useCallback(() => {
    setRun(false);
    setTriggerDialog(false);
    setTourInitialized(false);
    setCurrentTour(null);
    setStepIndex(0);
    if (currentTour === ETourNames.onboarding) {
      // used to blink onboarding for new accounts
      localStorage.removeItem('showOnboardingTour');
    }
  }, [currentTour]);

  return (
    <TourContext.Provider
      value={{
        run,
        currentTour,
        triggerDialog,
        stepIndex,
        isTourInitialized,
        steps,
        startTour,
        stopTour,
        setRun,
        setStepIndex,
        setTourInitialized,
        setTriggerDialog,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};

export const useTourContext = () => {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error('useTourContext must be used within a TourProvider');
  }
  return context;
};
