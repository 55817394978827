import { Box, Card, CardContent, Typography } from '@mui/material';
import { LearningPathCardProps } from '../data';

export default function LearningPathCard({
  itemAggregated,
  item,
  onCardClick,
}: LearningPathCardProps) {
  const assessmentCount = itemAggregated.aggregate?.count;
  return (
    <Card
      variant="outlined"
      id={`learning-path-${item.slug}`}
      sx={{
        cursor: 'pointer',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
        },
      }}
      onClick={() => onCardClick(item.title)}
    >
      <Box
        sx={{
          height: '140px',
          background: item.gradient,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          borderRadius: '4px 4px 0 0',
          color: 'white',
          textAlign: 'center',
        }}
      >
        <Box
          component="img"
          src={item.image_url}
          alt={item.title}
          sx={{
            width: '60px',
            height: '60px',
            position: 'absolute',
            top: '1rem',
          }}
        />
        <Typography
          variant="h6"
          sx={{
            marginTop: '70px',
            fontWeight: 'bold',
          }}
        >
          {item.title}
        </Typography>
      </Box>

      <CardContent>
        <Typography variant="subtitle2" color="textSecondary" gutterBottom>
          {item.description}
        </Typography>
        <Typography
          variant="subtitle2"
          color="textSecondary"
          fontWeight={'bold'}
          fontSize={15}
          gutterBottom
        >
          {`${assessmentCount} ${
            assessmentCount === 1 ? 'Assessment' : 'Assessments'
          }`}
        </Typography>
      </CardContent>
    </Card>
  );
}
