import { useAuth0 } from '@auth0/auth0-react';
import infoIcon from '@iconify/icons-eva/info-outline';
import { Icon } from '@iconify/react';
import {
  Alert,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Link,
  Paper,
  Popover,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import { ChangeEvent, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useGetCandidatesStatisticsQuery } from '../../@generated/facadeClient';
import {
  AVERAGE_ADDITIONAL_INTERVIEWER_COST_PER_HOUR,
  AVERAGE_ADDITIONAL_INTERVIEW_TIME,
  AVERAGE_TECHNICAL_INTERVIEWER_COST_PER_HOUR,
  AVERAGE_TECHNICAL_INTERVIEW_TIME,
} from '../../constants/averageInterviewCost';
import { getUserMetaData } from '../../utils/auth';
import {
  calculateAdditionalInterviewsSavedMoney,
  calculateAdditionalInterviewsSavedTime,
  calculateTechnicalInterviewsSavedMoney,
  calculateTechnicalInterviewsSavedTime,
} from '../../utils/savingsFormula';

export default function InterviewSettingsTab() {
  const { user } = useAuth0();
  const userMetaData = getUserMetaData(user);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setSeverity] = useState<'success' | 'error'>('success');
  const [message, setMessage] = useState('');

  const isInfoPopperOpen = Boolean(anchorEl);
  const popperId = isInfoPopperOpen ? 'calculator-info-popper' : undefined;

  const defaultInterviewCosts = JSON.parse(
    localStorage.getItem('interviewCosts') ||
      JSON.stringify({
        technicalInterviewerCostPerHour:
          AVERAGE_TECHNICAL_INTERVIEWER_COST_PER_HOUR,
        technicalInterviewTime: AVERAGE_TECHNICAL_INTERVIEW_TIME,
        additionalInterviewerCostPerHour:
          AVERAGE_ADDITIONAL_INTERVIEWER_COST_PER_HOUR,
        additionalInterviewTime: AVERAGE_ADDITIONAL_INTERVIEW_TIME,
      })
  );

  const [interviewCosts, setInterviewCosts] = useState(defaultInterviewCosts);

  const { data: candidatesStatistics } = useGetCandidatesStatisticsQuery({
    variables: {
      company_id: userMetaData.company_id,
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'network-only',
  });

  const totalCandidates =
    candidatesStatistics?.total_invented.aggregate?.count || 0;
  const totalPassedCandidates =
    candidatesStatistics?.total_passed.aggregate?.count || 0;

  const technicalInterviewsSavedMoney = calculateTechnicalInterviewsSavedMoney(
    totalCandidates,
    interviewCosts.technicalInterviewTime,
    interviewCosts.technicalInterviewerCostPerHour
  );

  const technicalInterviewsSavedTime = calculateTechnicalInterviewsSavedTime(
    totalCandidates,
    interviewCosts.technicalInterviewTime
  );

  const additionalInterviewsSavedMoney =
    calculateAdditionalInterviewsSavedMoney(
      totalCandidates,
      totalPassedCandidates,
      interviewCosts.additionalInterviewTime,
      interviewCosts.additionalInterviewerCostPerHour
    );

  const additionalInterviewsSavedTime = calculateAdditionalInterviewsSavedTime(
    totalCandidates,
    totalPassedCandidates,
    interviewCosts.additionalInterviewTime
  );

  function handleFieldChange(event: ChangeEvent<HTMLInputElement>) {
    const regexNumericOrDecimalOrEmpty = /^\d*(\.\d*)?$/;
    const isValid = regexNumericOrDecimalOrEmpty.test(event.target.value);
    if (!isValid) {
      return;
    }

    setInterviewCosts({
      ...interviewCosts,
      [event.target.name]: event.target.value,
    });
  }

  function handleSave() {
    localStorage.setItem('interviewCosts', JSON.stringify(interviewCosts));
    setShowSnackbar(true);
    setSeverity('success');
    setMessage('Interview settings saved');
  }

  function handleCancel() {
    localStorage.setItem(
      'interviewCosts',
      JSON.stringify(defaultInterviewCosts)
    );
    setInterviewCosts(defaultInterviewCosts);
    setShowSnackbar(true);
    setSeverity('success');
    setMessage('Interview settings canceled');
  }

  function handleSnackbarClose() {
    setShowSnackbar(false);
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ pt: 4 }}>
      <Card elevation={3} id="interviewSettings">
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              mb: '1.5rem',
            }}
          >
            <Typography variant="h6" display="block">
              Savings Calculator
            </Typography>

            <IconButton
              id="saving-calculator-show-explanation"
              edge="start"
              color="inherit"
              onClick={handleClick}
              aria-label="close"
              aria-describedby={popperId}
            >
              <Icon icon={infoIcon} />
            </IconButton>
            <Popover
              id={popperId}
              open={isInfoPopperOpen}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Paper elevation={2}>
                <Box sx={{ p: '1rem', maxWidth: '30rem' }}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="body2" display="block">
                        The numbers we provide as defaults are based on average
                        hourly costs for a&nbsp;
                        {
                          <Link
                            component={RouterLink}
                            to="https://www.levels.fyi/t/software-engineer/focus/devops?countryId=254&country=254"
                            underline="hover"
                            variant="subtitle2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            DevOps Engineer
                          </Link>
                        }{' '}
                        and a&nbsp;
                        {
                          <Link
                            component={RouterLink}
                            to="https://www.levels.fyi/t/software-engineering-manager?countryId=254"
                            underline="hover"
                            variant="subtitle2"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Software Engineering Manager
                          </Link>
                        }
                        {'. '}
                        A senior engineer typically spends at least one hour
                        discussing technical background, while the hiring
                        manager or team needs an additional hour to assess
                        culture fit and role expectations. Adjust these numbers
                        according to your own pay rates and the specific
                        dynamics of your hiring process.
                        <br />
                        <br />
                        For example, if you have a 1-hour technical interview
                        with two team members, consider their average salary and
                        allocate 2 hours, as both team members are busy for the
                        full hour.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Popover>
          </Box>
          <Grid item container spacing={2}>
            <Grid item xs={12} md={3}></Grid>
            <Grid container item xs={12} md={8} spacing={2}>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" display="block">
                    Technical Interviews
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <TextField
                    fullWidth
                    label="Cost Per Hour (US Dollars)"
                    name="technicalInterviewerCostPerHour"
                    variant="filled"
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    value={interviewCosts.technicalInterviewerCostPerHour}
                    onChange={handleFieldChange}
                  />
                  <Typography
                    variant="caption"
                    display="block"
                    color="text.secondary"
                  >
                    Based on your current settings, you've saved{' '}
                    {technicalInterviewsSavedMoney} USD on technical interviews
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Time Per Interview (Hours)"
                    name="technicalInterviewTime"
                    variant="filled"
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    value={interviewCosts.technicalInterviewTime}
                    onChange={handleFieldChange}
                  />
                  <Typography
                    variant="caption"
                    display="block"
                    color="text.secondary"
                  >
                    Based on your current settings, you've saved{' '}
                    {technicalInterviewsSavedTime} hours on technical interviews
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" display="block">
                    Additional Interviews
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ mb: 1 }}>
                  <TextField
                    fullWidth
                    label="Cost Per Hour - HR, Hiring Manager, CTO (US Dollars)"
                    name="additionalInterviewerCostPerHour"
                    variant="filled"
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    value={interviewCosts.additionalInterviewerCostPerHour}
                    onChange={handleFieldChange}
                  />
                  <Typography
                    variant="caption"
                    display="block"
                    color="text.secondary"
                  >
                    Based on your current settings, you've saved{' '}
                    {additionalInterviewsSavedMoney} USD on additional
                    interviews
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Time For Additional Interviews - HR, CTO, etc. (Hours)"
                    name="additionalInterviewTime"
                    variant="filled"
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    value={interviewCosts.additionalInterviewTime}
                    onChange={handleFieldChange}
                  />
                  <Typography
                    variant="caption"
                    display="block"
                    color="text.secondary"
                  >
                    Based on your current settings, you've saved{' '}
                    {additionalInterviewsSavedTime} hours on additional
                    interviews
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="caption" display="block">
                  Total Saved Money:{' '}
                  {technicalInterviewsSavedMoney +
                    additionalInterviewsSavedMoney}{' '}
                  USD, Total Saved Time:{' '}
                  {technicalInterviewsSavedTime + additionalInterviewsSavedTime}{' '}
                  hours
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions sx={{ justifyContent: 'flex-end' }}>
          <Button
            id="saving-calculator-reset-setting"
            onClick={handleCancel}
            variant="text"
          >
            Reset
          </Button>
          <Button
            id="saving-calculator-save-setting"
            type="submit"
            variant="contained"
            onClick={handleSave}
          >
            Save
          </Button>
        </CardActions>
      </Card>
      {showSnackbar && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Alert
            variant="filled"
            onClose={handleSnackbarClose}
            severity={severity}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
}
