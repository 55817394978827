import { SxProps, Theme } from '@mui/material';
import { Box } from '@mui/material';
import { ReactElement } from 'react';
import SimpleBarStyle from './SimpleBarStyle';

interface IScrollbarProps {
  children: ReactElement;
  sx?: SxProps<Theme>;
}

export default function Scrollbar({ children, sx = {} }: IScrollbarProps) {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (isMobile) {
    return <Box sx={{ overflowX: 'auto', ...sx }}>{children}</Box>;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <SimpleBarStyle
        id="app-sidebar"
        timeout={500}
        clickOnTrack={false}
        sx={sx}
      >
        {children}
      </SimpleBarStyle>
    </Box>
  );
}
