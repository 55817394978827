import { Box, ButtonBase } from '@mui/material';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface INavItemProps {
  href: string;
  icon: ReactNode;
  title: string;
  disabled?: boolean;
}

export default function NavItem({
  href,
  icon,
  title,
  disabled = false,
}: INavItemProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isRouteOrSubRouteActive = href ? pathname.startsWith(href) : false;

  const handleNavigate = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate(href);
  };

  return (
    <li>
      <ButtonBase
        id={`navbar-${title}`}
        sx={{
          alignItems: 'center',
          borderRadius: 1,
          display: 'flex',
          justifyContent: 'flex-start',
          pl: '1rem',
          pr: '1rem',
          py: '6px',
          textAlign: 'left',
          width: '100%',
          ...(isRouteOrSubRouteActive && {
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
          }),
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.04)',
          },
        }}
        onClick={handleNavigate}
        component="a"
        href={href}
        disabled={disabled}
      >
        {icon && (
          <Box
            component="span"
            sx={{
              alignItems: 'center',
              color: 'neutral.400',
              display: 'inline-flex',
              justifyContent: 'center',
              mr: 2,
              minWidth: 17,
              ...(isRouteOrSubRouteActive && {
                color: 'primary.main',
              }),
            }}
          >
            {icon}
          </Box>
        )}
        <Box
          component="span"
          sx={{
            color: 'neutral.400',
            flexGrow: 1,
            fontFamily: (theme) => theme.typography.fontFamily,
            fontSize: 15,
            fontWeight: 600,
            lineHeight: '1.5rem',
            whiteSpace: 'nowrap',
            ...(isRouteOrSubRouteActive && {
              color: 'common.white',
            }),
            ...(disabled && {
              color: 'neutral.500',
            }),
          }}
        >
          {title}
        </Box>
      </ButtonBase>
    </li>
  );
}
