import { useAuth0 } from '@auth0/auth0-react';
import { HelpOutline } from '@mui/icons-material';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import {
  Box,
  Container,
  Icon,
  IconButton,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { Link, Outlet } from 'react-router-dom';
import PrivateFooter from '../../components/Footer/PrivateFooter';
import { Navbar } from '../../components/Navbar';
import { useTourContext } from '../../components/Onboarding/OnboardingTourContext';
import {
  ETourNames,
  onboardingTourCompanySteps,
  onboardingTourPersonalSteps,
} from '../../components/Onboarding/tour';
import { ProgressLoader } from '../../components/ProgressLoader';
import { Sidebar } from '../../components/Sidebar';
import { useBilling } from '../../hooks/useBilling';
import { isPersonal } from '../../utils/auth';
import { ILayoutProps, onboardingToutIconStyle } from './data';
export default function CompanyLayout({
  isSidebarOpen,
  menuItems,
  handleSidebarOpen,
  handleSidebarClose,
}: ILayoutProps) {
  const {
    getAvailableCredits,
    companyAccessDetails,
    companyAccessDetailsLoading,
    isFreeTrialRegistration,
    isUnlimitedRegularPlan,
    canAccessProFeatures,
  } = useBilling();
  const availableCredits = getAvailableCredits();
  const { user } = useAuth0();
  const { startTour } = useTourContext();
  const tourSteps = isPersonal(user)
    ? onboardingTourPersonalSteps
    : onboardingTourCompanySteps;

  const showOnboardingTour =
    localStorage.getItem('showOnboardingTour') === 'true';

  useEffect(() => {
    if (isFreeTrialRegistration() && !showOnboardingTour) {
      localStorage.setItem('showOnboardingTour', 'true');
    }
  }, [isFreeTrialRegistration, showOnboardingTour]);

  if (
    (!companyAccessDetails || companyAccessDetailsLoading) &&
    !isFreeTrialRegistration()
  ) {
    return <ProgressLoader />;
  }
  const expirationSubDate = new Date(
    companyAccessDetails?.subscription_expiration_date || ''
  );

  return (
    <>
      <Box
        sx={(theme: Theme) => ({
          display: 'flex',
          flex: '1 1 auto',
          maxWidth: '100%',
          paddingTop: '4rem',
          [theme.breakpoints.up('lg')]: {
            paddingLeft: '17.5rem',
          },
        })}
      >
        <Box
          sx={{
            display: 'flex',
            flex: '1 1 auto',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Container sx={{ py: '2rem', pb: '1rem', minHeight: '100vh' }}>
            <Outlet />
          </Container>
          <PrivateFooter />
        </Box>
      </Box>
      <Navbar
        onSidebarOpen={handleSidebarOpen}
        extraInfo={
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <Typography variant="body2" color={'text.secondary'}>
              <Link
                id="navbar-current-credits"
                to={`/company/settings/subscription`}
                style={{ textDecoration: 'none', color: 'inherit' }}
              >
                {availableCredits <= 0 && canAccessProFeatures() ? (
                  <Tooltip
                    title={
                      isUnlimitedRegularPlan()
                        ? `Unlimited access until ${expirationSubDate.toDateString()}`
                        : 'Unlimited Credits with Tiered Pricing'
                    }
                  >
                    <span>
                      <Icon color="primary">
                        <AllInclusiveIcon />
                      </Icon>
                    </span>
                  </Tooltip>
                ) : (
                  <>
                    <b>{availableCredits}</b> Credit
                    {availableCredits === 1 ? '' : 's'} left
                  </>
                )}
              </Link>
            </Typography>

            <Tooltip title="Explore platform with step-by-step guidance.">
              <IconButton
                color={showOnboardingTour ? 'info' : 'default'}
                size="medium"
                onClick={() => startTour(ETourNames.onboarding, tourSteps)}
                sx={onboardingToutIconStyle(showOnboardingTour)}
              >
                <HelpOutline />
              </IconButton>
            </Tooltip>
          </Box>
        }
      />
      <Sidebar
        onClose={handleSidebarClose}
        open={isSidebarOpen}
        menuItems={menuItems}
      />
    </>
  );
}
