import { Navigate, Route, Routes } from 'react-router-dom';
import { CandidatesPageDetails } from './CandidatesPageDetails';
import { CandidatesPageList } from './CandidatesPageList';

export default function CandidatesPage() {
  return (
    <Routes>
      <Route path="/:assessment_link_id?" element={<CandidatesPageList />} />
      <Route path=":id/tests/:testId" element={<CandidatesPageDetails />} />
      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  );
}
