import infoIcon from '@iconify/icons-eva/info-outline';
import { Icon } from '@iconify/react';
import { Box, Chip, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { TestPrivatePartialFragment } from '../../@generated/types';

interface ITestDescriptionProps {
  test: TestPrivatePartialFragment;
}
export default function TestDescription({ test }: ITestDescriptionProps) {
  const testDifficulty = test.test_difficulty?.level || 'Unknown';
  const testTechRoles = test.test_tech_roles || [];
  const testTechServices = test.test_tech_services || [];
  return (
    <>
      <Box
        id="candidate-create-form-test-info"
        sx={{
          display: 'flex',
          gap: 1,
          textTransform: 'capitalize',
          justifyContent: 'space-between',
          flexDirection: test.dynamic_issues ? 'column' : 'row',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: 1,
            textTransform: 'capitalize',
            justifyItems: 'flex-end',
            justifyContent: 'flex-end',
            order: test.dynamic_issues ? 0 : 1,
          }}
        >
          {test.dynamic_issues && (
            <Tooltip
              title="Each candidate gets a random set of problems"
              placement="top"
            >
              <Chip
                color={'info'}
                size="small"
                label={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    <Icon icon={infoIcon} />
                    Dynamic 🎲
                  </div>
                }
              />
            </Tooltip>
          )}
          <Chip
            color={
              testDifficulty.toLowerCase() === 'hard'
                ? 'error'
                : testDifficulty.toLowerCase() === 'easy'
                ? 'success'
                : 'warning'
            }
            size="small"
            label={testDifficulty}
          />
        </Box>
        <Typography
          sx={{ order: test.dynamic_issues ? 1 : 0 }}
          variant="subtitle1"
          gutterBottom
        >
          {test.name || 'No name provided'}
        </Typography>
      </Box>
      <Typography
        variant="caption"
        display="block"
        gutterBottom
        dangerouslySetInnerHTML={{
          __html:
            test?.description_human?.replace(/\\n/gi, '<br /><br />') ??
            'No description available',
        }}
      />
      <Typography variant="subtitle1" gutterBottom>
        Roles
      </Typography>
      <Typography variant="caption" display="block" gutterBottom>
        {testTechRoles.length > 0
          ? testTechRoles.map((tech, index) => (
              <React.Fragment key={`role-${index}`}>
                {tech.tech_role?.name || 'Unknown'}
                {index < testTechRoles.length - 1 && ', '}
              </React.Fragment>
            ))
          : 'No roles available'}
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Technologies
      </Typography>
      <Box>
        {testTechServices.length > 0
          ? testTechServices.map((tech, index) => (
              <Chip
                label={tech.tech_service?.name || 'Unknown'}
                key={`service-${index}`}
                color="secondary"
                size="small"
                sx={{
                  mr: '0.625rem',
                  mb: '0.625rem',
                  textTransform: 'capitalize',
                }}
              />
            ))
          : 'No technologies available'}
      </Box>
    </>
  );
}
