import { Placement } from 'react-joyride';

export enum ETourNames {
  assessment = 'assessment',
  candidates = 'candidates',
  onboarding = 'onboarding',
  testCatalogue = 'testCatalogue',
  settings = 'settings',
  invitationLink = 'invitationLink',
}

export const tourStepsAssessment = [
  {
    target: '#assessment-sidebar',
    content: 'Access key features for the assessment in this sidebar.',
    placement: 'right' as Placement,
    disableBeacon: true,
  },
  {
    target: '#assessment-page-hide-side-bar-menu',
    content:
      'Hide the sidebar to maximize space for tools like the terminal or code editor.',
    placement: 'bottom-end' as Placement,
    disableBeacon: true,
  },
  {
    target: '#assessment-sidebar-menu-Home',
    content: 'Go back to the home page from here.',
    placement: 'right' as Placement,
    disableBeacon: true,
  },
  {
    target: '#assessment-sidebar-menu-Assessment-Description',
    content: 'Review the assessment details and requirements.',
    placement: 'right' as Placement,
    disableBeacon: true,
    // disableOverlay: true,
  },
  {
    target: '#assessment-sidebar-menu-Assessment-Diagram',
    content:
      'View the architecture diagram to understand the infrastructure setup and requirements.',
    placement: 'right' as Placement,
    disableBeacon: true,
    // disableOverlay: true,
  },
  {
    target: '#assessment-sidebar-menu-Working-Environment',
    content:
      'Access your working environment here, including tools like the code editor, cloud console, or terminal, depending on the assessment.',
    placement: 'right' as Placement,
    disableBeacon: true,
    // disableOverlay: true,
  },
  {
    target: '#assessment-page-begin-assessment',
    content:
      'Start your assessment by setting up the infrastructure. One credit will be used, and the timer will start after setup.',
    disableBeacon: true,
  },
  {
    target: '#assessment-page-check-solution',
    content:
      'Check the progress of your solution once the infrastructure is ready and you made some fixes.',
    disableBeacon: true,
  },
  {
    target: '#assessment-page-submit-solution',
    content:
      "Submit your solution for evaluation once you're satisfied with your work.",
    disableBeacon: true,
  },
];

export const onboardingTourPersonalSteps = [
  {
    target: '#app-sidebar',
    content: 'This is the app sidebar, your main navigation hub.',
    placement: 'right' as Placement,
    disableBeacon: true,
  },
  {
    target: '#navbar-current-credits',
    content: 'Check your subscription plan here and credits here.',
    placement: 'bottom' as Placement,
    data: {
      next: '/company/learning-paths',
    },
    disableBeacon: true,
  },
  {
    target: '#learning-path-linux-essentials',
    content:
      'Explore various learning paths. For example, choose the Linux Essentials path to get started.',
    data: {
      next: '/company/learning-paths/linux-essentials',
      previous: '/company/learning-paths',
    },
    disableBeacon: true,
  },
  {
    target: '#learning-path-assessment-13',
    content:
      'Explore this assessment. You can also find this and other assessments in the test catalog.',
    data: {
      next: '/company/tests-catalogue/test-details/13',
      previous: '/company/learning-paths',
    },
    disableBeacon: true,
  },
  {
    target: '#assessment-13-learning-resources',
    content:
      'Access technical resources to help you prepare for the assessment.',
    data: {
      previous: '/company/learning-paths/linux-essentials',
    },
    disableBeacon: true,
  },
  {
    target: '#assessment-13-full-description',
    content:
      'Review the full description to understand the assessment requirements.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
  },
  {
    target: '#assessment-13-arch-diagram',
    content: 'Explore the architecture diagram for a clearer understanding.',
    disableBeacon: true,
  },
  {
    target: '#test-catalog-launch-test-button-bottom',
    content:
      "When you're ready, go to the environment page to start the assessment. Click Next",
    data: {
      next: '/assessments/13/description',
      // previous: '/company/tests-catalogue/test-details/13',
    },
    disableBeacon: true,
  },
  {
    target: '#assessment-sidebar',
    content:
      'This is the assessment sidebar where you can access everything you need for the assessment.',
    placement: 'right' as Placement,
    disableBeacon: true,
    data: {
      previous: '/company/tests-catalogue/test-details/13',
      next: '/assessments/13/description',
    },
  },
  {
    target: '#assessment-page-begin-assessment',
    content:
      "Click 'Begin' to initiate the infrastructure setup. One credit will be used upon successful creation.",
    disableBeacon: true,
  },
  {
    target: '#assessment-page-check-solution',
    content:
      'Check your current solution progress once the infrastructure is created.',
    disableBeacon: true,
  },
  {
    target: '#assessment-page-submit-solution',
    content:
      "Once you're satisfied with your solution, submit it to evaluate your results.",
    disableBeacon: true,
  },
];
export const onboardingTourCompanySteps = [
  {
    target: '#app-sidebar',
    content: 'This is the app sidebar, your main navigation hub.',
    placement: 'right' as Placement,
    disableBeacon: true,
  },
  {
    target: '#navbar-current-credits',
    content: 'Check your subscription credits and plan here.',
    placement: 'bottom' as Placement,
    data: {
      next: '/company/candidates',
    },
    disableBeacon: true,
  },
  {
    target: '#candidate-invite-new',
    content: 'Invite new candidates to take the test here.',
    placement: 'left' as Placement,
    disableBeacon: true,
  },
  {
    target: '#candidate-create-form',
    content:
      "Enter the candidate's basic information such as email and name. Optionally, include their LinkedIn profile for better context.",
    placement: 'right' as Placement,
    data: {
      openDialog: true,
    },
    disableBeacon: true,
  },
  {
    target: '#candidate-create-form-test',
    content:
      'Choose a test for the candidate. Tests may include dynamic problem-solving tasks. Visit the Test Catalogue for detailed descriptions.',
    placement: 'right' as Placement,
    data: {
      openDialog: true,
    },
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-submit-invite',
    content:
      'Finalize the candidate profile and send a test invite for completion.',
    placement: 'top' as Placement,
    data: {
      openDialog: true,
    },
    disableBeacon: true,
  },
  {
    target: '#candidate-table-filters',
    content: 'Search for candidates by email, specific test, and results.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-table-header',
    content: 'Sort all candidates by email, login time, and more.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-table-list',
    content:
      "Browse the table to review each candidate's attempts, including their details and progress, by clicking on the row. Use the manage actions to manage attempt as needed.",
    placement: 'top' as Placement,
    data: {
      next: '/company/candidates/1/tests/1',
    },
    disableBeacon: true,
  },
  {
    target: '#candidate-test-results-page-card',
    content:
      'View the full test results once the candidate completes and submits the test. Access this page from the candidate table.',
    placement: 'top' as Placement,
    data: {
      previous: '/company/candidates',
    },
    disableBeacon: true,
  },
  {
    target: '#candidate-results-page-menu-action',
    content:
      'Also manage test results here with options to reset results, download reports, and more. Note: Some options are disabled for the demo.',
    placement: 'left' as Placement,
    disableBeacon: true,
  },
  {
    target: '#candidate-test-results-page-card-primary-results',
    content:
      'Review general test outcomes, including completion status, score, tasks completed, and benchmark comparisons.',
    placement: 'top' as Placement,
    disableBeacon: true,
  },
  {
    target: '#candidate-test-results-page-card-auto-checks',
    content:
      'Check which tasks the candidate successfully completed or failed. Click on it to explore more.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-test-results-page-card-problem-set',
    content:
      'For dynamic tests, review problems the candidate encountered during the test. Click on it to see selected.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-test-results-page-card-history',
    content:
      'View terminal history or cloud logs of the candidate’s activity. You can also download it from the action menu. Click on it to review terminal history.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-test-results-page-card-test-description',
    content:
      'Quickly review the test description and the associated requirements. Click on it to see full description.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-test-results-page-card-engagement-summary',
    content:
      'Check an AI-generated summary of the candidate’s engagement within the environment. Click on it to review the summary.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-test-results-page-card-engagement-summary',
    content:
      'Prepare follow-up questions based on candidate actions during the test to verify their approach. Click on it to review the follow-up questions.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-test-results-page-activity-replay',
    content:
      'Interact with the test recording to closely review the candidate’s activity in the environment. Click to review the recording session.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#recordings-create-new-link',
    content:
      'Share this activity recording publicly with colleagues for collaborative review. Note: This option is disabled for the demo.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    data: {
      next: '/company/candidates',
    },
  },
];

export const tourStepsCandidates = [
  {
    target: '#candidate-invite-new',
    content: 'Invite new candidates to take the test here.',
    placement: 'left' as Placement,
    disableBeacon: true,
  },
  {
    target: '#candidate-create-form',
    content:
      "Enter the candidate's basic information such as email and name. Optionally, include their LinkedIn profile for better context.",
    placement: 'right' as Placement,
    data: {
      openDialog: true,
    },
    disableBeacon: true,
  },
  {
    target: '#candidate-create-form-test',
    content:
      'Choose a test for the candidate. Tests may include dynamic problem-solving tasks. Visit the Test Catalogue for detailed descriptions.',
    placement: 'right' as Placement,
    data: {
      openDialog: true,
    },
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-submit-invite',
    content:
      'Finalize the candidate profile and send a test invite for completion.',
    placement: 'top' as Placement,
    data: {
      openDialog: true,
    },
    disableBeacon: true,
  },
  {
    target: '#candidate-table-filters',
    content: 'Search for candidates by email, specific test, and results.',
    placement: 'top' as Placement,
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-table-header',
    content: 'Sort all candidates by email, login time, and more.',
    placement: 'top' as Placement,
    disableBeacon: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-table-list',
    content:
      "Browse the table to review each candidate's attempts, including their details and progress, by clicking on the row. Use the manage actions to manage attempt as needed.",
    placement: 'top' as Placement,
    data: {
      next: '/company/candidates/1/tests/1',
    },
    disableBeacon: true,
  },
  {
    target: '#candidate-test-results-page-card',
    content:
      'View the full test results once the candidate completes and submits the test. Access this page from the candidate table.',
    placement: 'top' as Placement,
    data: {
      previous: '/company/candidates',
    },
    disableBeacon: true,
  },
  {
    target: '#candidate-results-page-menu-action',
    content:
      'Also manage test results here with options to reset results, download reports, and more. Note: Some options are disabled for the demo.',
    placement: 'left' as Placement,
    disableBeacon: true,
  },
  {
    target: '#candidate-test-results-page-card-primary-results',
    content:
      'Review general test outcomes, including completion status, score, tasks completed, and benchmark comparisons.',
    placement: 'top' as Placement,
    disableBeacon: true,
  },
  {
    target: '#candidate-test-results-page-card-auto-checks',
    content:
      'Check which tasks the candidate successfully completed or failed. Click on it to explore more.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-test-results-page-card-problem-set',
    content:
      'For dynamic tests, review problems the candidate encountered during the test. Click on it to see selected.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-test-results-page-card-history',
    content:
      'View terminal history or cloud logs of the candidate’s activity. You can also download it from the action menu. Click on it to review terminal history.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-test-results-page-card-test-description',
    content:
      'Quickly review the test description and the associated requirements. Click on it to see full description.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-test-results-page-card-engagement-summary',
    content:
      'Check an AI-generated summary of the candidate’s engagement within the environment. Click on it to review the summary.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-test-results-page-card-engagement-summary',
    content:
      'Prepare follow-up questions based on candidate actions during the test to verify their approach. Click on it to review the follow-up questions.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#candidate-test-results-page-activity-replay',
    content:
      'Interact with the test recording to closely review the candidate’s activity in the environment. Click to review the recording session.',
    placement: 'top' as Placement,
    disableBeacon: true,
    disableOverlay: true,
    spotlightClicks: true,
  },
  {
    target: '#recordings-create-new-link',
    content:
      'Share this activity recording publicly with colleagues for collaborative review. Note: This option is disabled for the demo.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    data: {
      next: '/company/candidates',
    },
  },
];

export const tourStepsInvitationLink = [
  {
    target: '#invitation-link-title',
    content:
      'Invitation links allow you to create and share a unique link for a specific test with flexible settings.',
    placement: 'right' as Placement,
    disableBeacon: true,
  },
  {
    target: '#invitation-link-filters',
    content:
      'Filter your links by the associated test and their active status.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
  },
  {
    target: '#invitation-link-list',
    content:
      'Manage all your links here. Review candidates’ test attempts by clicking on the corresponding job titles.',
    placement: 'top' as Placement,
    disableBeacon: true,
  },
  {
    target: '#invitation-link-create',
    content: 'Click here to create a new invitation link.',
    placement: 'left' as Placement,
    disableBeacon: true,
  },
  {
    target: '#invitation-link-form-job-title',
    content: 'Name your link for easy identification.',
    placement: 'bottom' as Placement,
    data: {
      openDialog: true,
    },
    disableBeacon: true,
  },
  {
    target: '#invitation-link-form-test-id',
    content:
      'Specify the test you want to associate with this link. If the test allows dynamic issues, you can also choose which ones to include.',
    placement: 'bottom' as Placement,
    data: {
      openDialog: true,
    },
    disableBeacon: true,
  },
  {
    target: '#invitation-link-form-expires-at',
    content:
      'Set an expiration date for the link to control how long candidates can register. Leave this field blank to keep the link valid indefinitely.',
    placement: 'bottom' as Placement,
    data: {
      openDialog: true,
    },
    disableBeacon: true,
  },
  {
    target: '#invitation-link-form-max-applicants',
    content:
      'Limit the maximum number of candidates who can register with this link to avoid unexpected usage or costs. Leave blank for unlimited registrations.',
    placement: 'bottom' as Placement,
    data: {
      openDialog: true,
    },
    disableBeacon: true,
  },
  {
    target: '#invitation-link-modify-create',
    content:
      'After creating the link, copy it and share it with potential candidates. You can always come back to modify or manage the link later.',
    placement: 'top' as Placement,
    data: {
      openDialog: true,
    },
    disableBeacon: true,
  },
];

export const tourStepsPersonalSettings = [
  {
    target: '#pricing-cards',
    content: 'Manage and view your subscription plan.',
    placement: 'bottom' as Placement,
    data: {
      next: '/company/settings/billing',
      previous: '/company/settings/subscription',
    },
    disableBeacon: true,
  },
  {
    target: '#billing-details',
    content: 'Update and review your billing information.',
    placement: 'bottom' as Placement,
    data: {
      previous: '/company/settings/subscription',
    },
    disableBeacon: true,
  },
  {
    target: '#invoice-history',
    content: 'Access your invoice history and save receipts.',
    placement: 'top' as Placement,
    disableBeacon: true,
  },
];

export const tourStepsPersonalTestCatalogue = [
  {
    target: '#test-catalog-filters',
    content:
      'Use filters to narrow down tests by categories, keywords, difficulty levels, and more.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
  },
  {
    target: '#test-catalogue-filter-Availability',
    content:
      'Filter tests to view the free ones available during the trial period. We have made them free as an example.',
    placement: 'top' as Placement,
    disableBeacon: true,
    data: {
      previous: 'company/tests-catalogue',
      next: `/company/tests-catalogue?filtersParams=%7B"tech_roles"%3A"All"%2C"tech_services"%3A"All"%2C"test_difficulties"%3A"All"%2C"test_types"%3A"All"%2C"test_categories"%3A"All"%2C"availability"%3A"free"%2C"tech_service_categories"%3A"All"%2C"dynamic_issues"%3A"All"%2C"sort_by"%3A"asc"%2C"search"%3A""%2C"company_id"%3A0%7D&page=0&limit=5`,
    },
  },
  {
    target: '#test-card-21',
    content:
      'Here is an Kubernetes test. You can learn more about the test or directly access the test environment. Learn more',
    data: {
      next: '/company/tests-catalogue/test-details/21',
      previous: `/company/tests-catalogue?filtersParams=%7B"tech_roles"%3A"All"%2C"tech_services"%3A"All"%2C"test_difficulties"%3A"All"%2C"test_types"%3A"All"%2C"test_categories"%3A"All"%2C"availability"%3A"free"%2C"tech_service_categories"%3A"All"%2C"dynamic_issues"%3A"All"%2C"sort_by"%3A"asc"%2C"search"%3A""%2C"company_id"%3A0%7D&page=0&limit=5`,
    },
    disableBeacon: true,
  },
  {
    target: '#test-catalog-launch-test-button-top',
    content: 'Launch the test directly from this button.',
    placement: 'left-start' as Placement,
    disableBeacon: true,
    data: {
      previous: `/company/tests-catalogue?filtersParams=%7B"tech_roles"%3A"All"%2C"tech_services"%3A"All"%2C"test_difficulties"%3A"All"%2C"test_types"%3A"All"%2C"test_categories"%3A"All"%2C"availability"%3A"free"%2C"tech_service_categories"%3A"All"%2C"dynamic_issues"%3A"All"%2C"sort_by"%3A"asc"%2C"search"%3A""%2C"company_id"%3A0%7D&page=0&limit=5`,
    },
  },
  {
    target: '#assessment-info',
    content: 'View the test overview, including roles and key statistics.',
    placement: 'right-start' as Placement,
    disableBeacon: true,
  },
  {
    target: '#assessment-21-short-description',
    content: 'A brief description highlighting the key points of this test.',
    disableBeacon: true,
  },
  {
    target: '#assessment-21-learning-resources',
    disableBeacon: true,
    content:
      'Access learning resources covering concepts used in this test to help you prepare.',
  },
  {
    target: '#assessment-21-full-description',
    disableBeacon: true,
    content:
      'Detailed information about the test, also visible in the test environment.',
  },
  {
    target: '#assessment-21-arch-diagram',
    disableBeacon: true,
    content:
      'Explore an architecture diagram that helps you understand the test requirements and structure.',
  },
  {
    target: '#test-catalog-launch-test-button-bottom',
    disableBeacon: true,
    content:
      'If you want to begin launch the test environment from this button.',
  },
];

export const tourStepsCompanySettings = [
  {
    target: '#company-cost-estimator',
    content: 'Estimate costs and compare pricing options that suit your needs.',
    disableBeacon: true,
    data: {
      previous: '/company/settings/subscription',
    },
  },
  {
    target: '#pricing-cards',
    content: 'Manage and view your subscription plan.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    data: {
      next: '/company/settings/billing',
      previous: '/company/settings/subscription',
    },
  },
  {
    target: '#billing-details',
    content: 'Update and review your billing information.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    data: {
      previous: '/company/settings/subscription',
    },
  },
  {
    target: '#invoice-history',
    content: 'Access your invoice history and save receipts.',
    placement: 'top' as Placement,
    disableBeacon: true,
    data: {
      next: '/company/settings/team',
      previous: '/company/settings/billing',
    },
  },
  {
    target: '#company-members',
    content: 'Easily manage your company members.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    data: {
      previous: '/company/settings/billing',
    },
  },
  {
    target: '#admin-invite',
    content: 'Send invitations to add a new admin to your team.',
    placement: 'left' as Placement,
    disableBeacon: true,
  },
  {
    target: '#admin-delete',
    content: 'Remove an admin user from your company team.',
    placement: 'left' as Placement,
    disableBeacon: true,
    data: {
      next: '/company/settings/dashboard',
    },
  },
  {
    target: '#interviewSettings',
    content: 'Track and calculate your savings using the platform.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    data: {
      previous: '/company/settings/team',
    },
  },
];

export const tourStepsCompanyTestCatalogue = [
  {
    target: '#test-catalog-filters',
    content:
      'Use the filters to refine your search for tests by categories, keywords, difficulty levels, and more. These options help you quickly find the most relevant tests for your needs.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
  },
  {
    target: '#test-catalogue-filter-Availability',
    content:
      'Now we filter the catalogue to show free tests available during the trial period.',
    placement: 'bottom' as Placement,
    disableBeacon: true,
    data: {
      previous: 'company/tests-catalogue',
      next: `/company/tests-catalogue?filtersParams=%7B"tech_roles"%3A"All"%2C"tech_services"%3A"All"%2C"test_difficulties"%3A"All"%2C"test_types"%3A"All"%2C"test_categories"%3A"All"%2C"availability"%3A"free"%2C"tech_service_categories"%3A"All"%2C"dynamic_issues"%3A"All"%2C"sort_by"%3A"asc"%2C"search"%3A""%2C"company_id"%3A0%7D&page=0&limit=5`,
    },
  },
  {
    target: '#test-card-21',
    content:
      'This is an example of a Kubernetes test. You can learn more about it or directly launch the test environment to explore its content. Lear more',
    data: {
      next: '/company/tests-catalogue/test-details/21',
      previous: `/company/tests-catalogue?filtersParams=%7B"tech_roles"%3A"All"%2C"tech_services"%3A"All"%2C"test_difficulties"%3A"All"%2C"test_types"%3A"All"%2C"test_categories"%3A"All"%2C"availability"%3A"free"%2C"tech_service_categories"%3A"All"%2C"dynamic_issues"%3A"All"%2C"sort_by"%3A"asc"%2C"search"%3A""%2C"company_id"%3A0%7D&page=0&limit=5`,
    },
    disableBeacon: true,
  },
  {
    target: '#test-catalog-launch-test-button-top',
    content:
      'Launch the test environment directly using this button to evaluate its structure and features.',
    disableBeacon: true,
    placement: 'left-start' as Placement,
    data: {
      previous: `/company/tests-catalogue?filtersParams=%7B"tech_roles"%3A"All"%2C"tech_services"%3A"All"%2C"test_difficulties"%3A"All"%2C"test_types"%3A"All"%2C"test_categories"%3A"4"%2C"availability"%3A"free"%2C"tech_service_categories"%3A"All"%2C"dynamic_issues"%3A"All"%2C"sort_by"%3A"asc"%2C"search"%3A""%2C"company_id"%3A21%7D&page=0&limit=5`,
    },
  },
  {
    target: '#test-catalog-invite-candidate',
    content: 'Invite candidates directly for this specific test.',
    placement: 'left-start' as Placement,
    disableBeacon: true,
  },
  {
    target: '#assessment-info',
    content:
      'Review the test overview, including roles, objectives, and statistic summarizing its purpose and structure.',
    placement: 'top-start' as Placement,
    disableBeacon: true,
  },
  {
    target: '#assessment-21-short-description',
    content:
      'A high-level summary of the test to help you understand its main objectives.',
    disableBeacon: true,
  },
  {
    target: '#assessment-21-learning-resources',
    content:
      'Access learning materials related to this test to help candidates prepare effectively.',
    disableBeacon: true,
  },
  {
    target: '#assessment-21-full-description',
    content:
      'A detailed description of the test, including requirements and expected outcomes.',
    disableBeacon: true,
  },
  {
    target: '#assessment-21-arch-diagram',
    content:
      'The architecture diagram helps candidates understand the technical structure and requirements of the test.',
    disableBeacon: true,
  },
  {
    target: '#test-catalog-launch-test-button-bottom',
    content:
      'Start the test environment to explore the evaluation process, or return to invite a candidate for assessment.',
    disableBeacon: true,
  },
];
