import { User, useAuth0 } from '@auth0/auth0-react';
import personDeleteFill from '@iconify/icons-eva/person-delete-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import {
  Alert,
  AlertColor,
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  LinearProgress,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useMutation } from 'react-query';
import { useGetUsersQuery } from '../../../@generated/facadeClient';
import { UsersPartialFragment } from '../../../@generated/types';
import { SubscriptionAlert } from '../../../components/SubscriptionAlert';
import { ESubscriptionFeaturesList } from '../../../constants/features';
import { useBilling } from '../../../hooks/useBilling';
import { getDateTime } from '../../../utils';
import { getUserMetaData } from '../../../utils/auth';
import { inviteUser, redoFn } from '../utils';
import CreateDialog from './CreateDialog';
import DeleteUserDialog from './DeleteUserDialog';

const role_admin_id = 1;
export default function Team() {
  const { user, getAccessTokenSilently } = useAuth0();
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [severity, setSeverity] = useState<AlertColor>('success');
  const [message, setMessage] = useState('');
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [deleteUser, setDeleteUser] = useState<UsersPartialFragment | null>(
    null
  );

  const { canAccessProFeatures } = useBilling();
  const {
    data: adminsData,
    loading,
    refetch,
  } = useGetUsersQuery({
    variables: {
      company_id: getUserMetaData(user)?.company_id,
      company_role_id: role_admin_id,
    },
  });

  const { mutate: invite } = useMutation(
    async ({ email }: { email: string }) => {
      const token = await getAccessTokenSilently();
      await inviteUser(token, {
        email,
        companyId: getUserMetaData(user)?.company_id,
      });
    },
    {
      onError: (error: AxiosError) => {
        handleError(
          (error?.response?.data as { error: string })?.error ??
            `Something went wrong. Please try again.`
        );
      },
    }
  );

  const handleCreateDialogOpen = () => {
    setShowCreateDialog(true);
  };

  const handleCreateDialogClose = () => {
    setShowCreateDialog(false);
  };

  const handleCreateDialogSuccess = (user: User) => {
    setShowCreateDialog(false);
    if (user.email) {
      invite(
        { email: user.email },
        {
          onSuccess: () => {
            const name = user?.name ?? user?.email;
            handleSuccess(`Admin ${name} was successfully invited!`);
            redoFn(refetch, 5, 1000);
          },
        }
      );
    }
  };

  const handleSuccess = (message: string) => {
    setShowSnackbar(true);
    setSeverity('success');
    setMessage(message);
  };

  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };

  const handleUserDelete = (testTrial: UsersPartialFragment) => {
    setDeleteUser(testTrial);
  };

  const handleDeleteDialogClose = () => {
    setDeleteUser(null);
  };

  const handleDeleteDialogSuccess = (message: string) => {
    setDeleteUser(null);
    handleSuccess(message);
    redoFn(refetch, 5, 1000);
  };

  const handleError = (error: string) => {
    setShowSnackbar(true);
    setSeverity('error');
    setMessage(error);
    setDeleteUser(null);
    setShowCreateDialog(false);
  };

  const admins = adminsData?.users || [];

  return (
    <Grid container spacing={4} sx={{ pt: '0.5rem' }}>
      <Grid item xs={12}>
        <Grid item xs={12} sx={{ mb: '1.5rem' }}>
          {!canAccessProFeatures() && (
            <SubscriptionAlert
              features={ESubscriptionFeaturesList.TEAMS_PAGE}
            />
          )}
        </Grid>
        <Card id="company-members">
          <CardContent>
            <Grid container>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    height: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <Stack>
                    <Typography variant="h6">Company Members</Typography>
                  </Stack>
                  <Button
                    id="admin-invite"
                    variant="contained"
                    startIcon={<Icon icon={plusFill} />}
                    onClick={handleCreateDialogOpen}
                    disabled={!canAccessProFeatures()}
                  >
                    Invite
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
          <PerfectScrollbar>
            <Table sx={{ minWidth: 600 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Member</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Last Login</TableCell>
                  <TableCell>Role</TableCell>
                  <TableCell width={'100px'}>actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {admins.map((admin) => {
                  const {
                    id,
                    name,
                    email,
                    picture,
                    company_role,
                    created_at,
                    logged_at,
                    userhash,
                  } = admin;

                  return (
                    <TableRow hover key={`admin${id}`}>
                      <TableCell>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Avatar
                            alt={email}
                            src={picture ?? ''}
                            sx={{
                              width: '2.625rem',
                              height: '2.625rem',
                              mr: '1rem',
                            }}
                          />
                          <Box>
                            <Typography variant="subtitle2">
                              {name} &nbsp;
                            </Typography>
                            <Typography variant="body2">
                              {email} &nbsp;
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>{getDateTime(created_at)}</TableCell>
                      <TableCell>{getDateTime(logged_at)}</TableCell>
                      <TableCell sx={{ textTransform: 'capitalize' }}>
                        {company_role?.name}
                      </TableCell>
                      <TableCell>
                        <IconButton
                          id="admin-delete"
                          sx={{
                            p: '8px',
                            padding: '8px',
                            borderRadius: '50%',
                            minWidth: 'auto',
                          }}
                          disabled={userhash === getUserMetaData(user).userhash}
                          title="Delete Admin"
                          onClick={() => handleUserDelete(admin)}
                        >
                          <Icon
                            icon={personDeleteFill}
                            width="20"
                            height="20"
                          />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </PerfectScrollbar>
          {loading && <LinearProgress sx={{ width: '100%' }} />}
          {showSnackbar && (
            <Snackbar
              open
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <Alert
                variant="filled"
                onClose={handleSnackbarClose}
                severity={severity}
              >
                {message}
              </Alert>
            </Snackbar>
          )}
        </Card>
      </Grid>
      {showCreateDialog && (
        <CreateDialog
          handleClose={handleCreateDialogClose}
          onSuccess={handleCreateDialogSuccess}
          onError={handleError}
        />
      )}
      {!!deleteUser && (
        <DeleteUserDialog
          user={deleteUser}
          onClose={handleDeleteDialogClose}
          onSuccess={handleDeleteDialogSuccess}
          onError={handleError}
        />
      )}
    </Grid>
  );
}
