import { useAuth0 } from '@auth0/auth0-react';
import arrowBackFill from '@iconify/icons-eva/arrow-back-fill';
import { Icon } from '@iconify/react';
import { CheckCircle, Error, NotInterested } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Chip,
  Divider,
  LinearProgress,
  Link as LinkMui,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetLearningPathsDetailsQuery } from '../../../@generated/facadeClient';
import { AssessmentAlert } from '../../../components/AssessmentAlert';
import ClaimBadge from '../../../components/Badges/ClaimBadge';
import { copyBadgePublicUrl } from '../../../components/Badges/data';
import useClaimBadge from '../../../components/Badges/useClaimBadge';
import { useBilling } from '../../../hooks/useBilling';
import { getUserMetaData } from '../../../utils/auth';
import UpgradePlanDialog from '../../TestInsights/components/UpgradePlanDialog';

export default function LearningPathDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams<{ slug: string }>();
  const { user } = useAuth0();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [showUpgradePlanDialogOpen, setUpgradePlanDialogOpen] = useState(false);

  const originTitle = location.state?.originTitle || 'Learning Paths';

  const { canAccessPersonalAccountFeatures } = useBilling();

  const { data, loading, refetch } = useGetLearningPathsDetailsQuery({
    variables: {
      slug: params.slug || '',
    },
  });

  const handleGoBack = () => {
    navigate(-1);
  };

  const assessments = data?.learning_paths_tests;
  const learningPath = data?.learning_paths[0];
  const badge = data?.learning_paths[0].badge;

  const assessmentsCount =
    data?.learning_paths[0]?.learning_paths_tests_total.aggregate?.count || 0;
  const assessmentsCompleted =
    data?.learning_paths[0]?.learning_paths_tests_completed.aggregate?.count ||
    0;
  const allAssessmentsCompleted = assessmentsCompleted === assessmentsCount;
  const {
    claimAlertMessage,
    claimAlertSeverity,
    claimAlertOpen,
    claimBadge,
    claiming,
    handleClaimAlertClose,
  } = useClaimBadge({
    badgeId: badge?.badge_id || '',
    recipientEmail: user?.email || '',
    learning_path_id: learningPath?.id,
    testId: null,
    userId: getUserMetaData(user).local_user_id,
  });
  const badgeAwards = badge?.badge_awards || [];
  const awardAvailable = badge?.badge_id && badgeAwards.length > 0;

  const handleBadgeAction = async () => {
    if (awardAvailable) {
      const publicUrl = badgeAwards[0].public_url;
      const recipient = badgeAwards[0].recipient_email;
      copyBadgePublicUrl(publicUrl, recipient, setSnackbarOpen);
    } else {
      await claimBadge();
      refetch();
    }
  };

  function handleGoToTestDetails(id: number, originTitle: string) {
    navigate(`/company/tests-catalogue/test-details/${id}`, {
      state: { originTitle: originTitle },
    });
  }

  return (
    <>
      <Stack sx={{ pt: '2rem' }} spacing={6}>
        <LinkMui
          sx={{
            display: 'inline-flex',
            alignItems: 'center',
            textDecoration: 'none',
            color: 'text.primary',
            '&:hover': {
              textDecoration: 'underline',
            },
          }}
          component={Link}
          to={'/company/learning-paths'}
          onClick={handleGoBack}
        >
          <Icon
            icon={arrowBackFill}
            width={24}
            height={24}
            style={{ marginRight: '.5rem' }}
          />
          <Typography variant={'subtitle2'} fontWeight={500}>
            {`Back to ${originTitle}`}
          </Typography>
        </LinkMui>

        {loading ? (
          <LinearProgress />
        ) : (
          <Card variant="outlined" sx={{ p: 3 }}>
            <Typography variant="h5" fontWeight="bold" gutterBottom>
              {learningPath?.title}
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: 2,
                padding: 1,
                justifyContent: 'space-around',
                alignItems: 'center',
                flexWrap: 'wrap',
                '@media (max-width:600px)': {
                  flexDirection: 'column',
                  alignItems: 'flex-center',
                },
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  '@media (max-width:600px)': {
                    alignItems: 'flex-center',
                    // textAlign: 'left',
                  },
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  Assessments
                </Typography>
                <Typography
                  variant="h4"
                  color="textSecondary"
                  fontWeight="bold"
                >
                  {assessmentsCount}
                </Typography>
              </CardContent>

              <Divider
                orientation={isMobile ? 'horizontal' : 'vertical'}
                flexItem
              />

              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center',
                  '@media (max-width:600px)': {
                    alignItems: 'flex-center',
                  },
                }}
              >
                <Typography variant="subtitle1" fontWeight="bold">
                  Completed
                </Typography>
                <Typography variant="h5" color="textSecondary">
                  {assessmentsCompleted}/{assessmentsCount}
                </Typography>
              </CardContent>
            </Box>

            <Divider />

            <Box sx={{ my: 3 }}>
              <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                Introduction
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {learningPath?.introduction}
              </Typography>
            </Box>

            <Divider />

            {assessments && assessments?.length > 0 && (
              <Box sx={{ my: 3 }}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Assessments
                </Typography>
                <Typography
                  variant="subtitle2"
                  color="textSecondary"
                  gutterBottom
                >
                  We recommend completing the assessments in the following
                  order:
                </Typography>
                <List>
                  {assessments.map((assessment) => {
                    let statusLabel = 'Not Started';
                    let statusIcon = <NotInterested />;

                    const blockAccess =
                      !canAccessPersonalAccountFeatures() &&
                      assessment.test.requires_payment;

                    const passedCount =
                      assessment.test.test_trials_aggregate_passed.aggregate
                        ?.count ?? 0;
                    const failedCount =
                      assessment.test.test_trials_aggregate_failed.aggregate
                        ?.count ?? 0;

                    if (passedCount > 0) {
                      statusLabel = 'Passed';
                      statusIcon = <CheckCircle color="primary" />;
                    } else if (failedCount > 0) {
                      statusLabel = 'Failed';
                      statusIcon = <Error color="error" />;
                    }
                    return (
                      <ListItem
                        id={`learning-path-assessment-${assessment.test.brokee_id}`}
                        key={assessment.test.brokee_id}
                        sx={{
                          borderBottom: '1px solid #eee',
                          '&:last-child': { borderBottom: 'none' },
                          cursor: 'pointer',

                          opacity: blockAccess ? 0.5 : 1,
                          flexDirection: { xs: 'column', sm: 'row' },
                          alignItems: 'flex-center',
                          gap: { xs: 1, sm: 1 },
                          padding: { xs: '8px 0', sm: '16px 0' },
                        }}
                        onClick={() => {
                          if (blockAccess) {
                            setUpgradePlanDialogOpen(true);
                          } else {
                            handleGoToTestDetails(
                              assessment.test.brokee_id,
                              learningPath?.title || 'Learning Paths'
                            );
                          }
                        }}
                      >
                        <ListItemAvatar>
                          <Avatar
                            src={assessment.test.featured_image || ''}
                            alt={assessment.test.name}
                            sx={{
                              width: '2.525rem',
                              height: '2.525rem',
                            }}
                            variant="square"
                          />
                        </ListItemAvatar>
                        <ListItemText
                          primary={assessment.test.name}
                          secondary={assessment.test.description_short}
                          sx={{
                            textAlign: { xs: 'center', sm: 'left' },
                          }}
                        />
                        <Box
                          display="flex"
                          alignItems="center"
                          gap={1}
                          sx={{
                            justifyContent: { xs: 'end', sm: 'flex-end' },
                            mt: { xs: 1, sm: 0 },
                          }}
                        >
                          <Chip
                            label={statusLabel}
                            variant="outlined"
                            icon={statusIcon}
                            size="small"
                          />
                        </Box>
                      </ListItem>
                    );
                  })}
                </List>
                <Divider />
              </Box>
            )}

            {badge && (
              <Box sx={{ my: 3 }}>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  Badge
                </Typography>
                {claiming && <LinearProgress />}
                {!allAssessmentsCompleted ? (
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    gutterBottom
                  >
                    Complete this learning path to earn the{' '}
                    <LinkMui
                      href={badge.public_url}
                      target="_blank"
                      rel="noopener noreferrer"
                      underline="hover"
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                        textDecoration: 'none',
                      }}
                    >
                      {badge.title} Badge
                    </LinkMui>{' '}
                    and showcase your skills to others!
                  </Typography>
                ) : (
                  <CardContent>
                    <Card
                      sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                        ':hover': {
                          boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                          transition:
                            'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                        },
                      }}
                    >
                      <ClaimBadge
                        available={!!awardAvailable}
                        badgeName={badge?.title || ''}
                        onAction={handleBadgeAction}
                      />
                    </Card>
                  </CardContent>
                )}
              </Box>
            )}
          </Card>
        )}
      </Stack>
      <AssessmentAlert
        text={claimAlertMessage}
        open={claimAlertOpen}
        severity={claimAlertSeverity}
        onClose={handleClaimAlertClose}
      />
      <AssessmentAlert
        text={'Public link for your badge copied!'}
        open={snackbarOpen}
        severity={'success'}
        variant={'standard'}
        onClose={() => setSnackbarOpen(false)}
      />
      <UpgradePlanDialog
        open={showUpgradePlanDialogOpen}
        handleClose={() => setUpgradePlanDialogOpen(false)}
        text={`Upgrade your account to get access this assessment`}
      />
    </>
  );
}
