import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';

import {
  Menu,
  MenuItem,
  Button,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { IconifyIcon } from '@iconify/types';

interface IMoreMenuProps {
  buttonText: string;
  buttonIcon: IconifyIcon;
  menuItems: {
    icon: IconifyIcon;
    text: string;
    onClick(): void;
    disabled?: boolean;
  }[];
}

export default function MoreMenu({
  buttonText,
  buttonIcon,
  menuItems,
}: IMoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleClose = () => setIsOpen(false);

  return (
    <>
      <Button
        ref={ref}
        id="candidate-results-page-menu-action"
        variant="contained"
        endIcon={<Icon icon={buttonIcon} />}
        onClick={() => setIsOpen(true)}
      >
        {buttonText}
      </Button>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={handleClose}
        PaperProps={{
          sx: { width: 220, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {menuItems.map(({ icon, text, disabled = false, onClick }) => (
          <MenuItem
            key={text}
            sx={{ color: 'text.secondary' }}
            disabled={disabled}
            onClick={() => {
              onClick();
              handleClose();
            }}
          >
            <ListItemIcon>
              <Icon icon={icon} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={text}
              primaryTypographyProps={{ variant: 'body2' }}
            />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
