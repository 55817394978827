import { Card, CardContent, CardHeader, Typography } from '@mui/material';
import { FC } from 'react';
import { ITestDescriptionCardProps } from '../utils';
import { setUserHashInAssessmentDescription } from '../../AssessmentPage/utils';

const TestDescriptionCard: FC<ITestDescriptionCardProps> = ({
  brokee_id,
  title,
  description,
  descriptionLong,
  userhash,
}) => {
  const descriptionWithHash = setUserHashInAssessmentDescription(
    brokee_id,
    descriptionLong,
    userhash
  );
  return (
    <Card sx={{ p: '1.2rem' }} elevation={0}>
      <CardHeader
        sx={{ padding: '4px 12px', mb: '1rem' }}
        title={
          title?.split(':')[1] === ' '
            ? title?.split(':')[0]
            : title?.split(':')[1]
        }
      />
      <CardContent sx={{ padding: '4px 12px' }}>
        <Typography
          variant="body2"
          display="block"
          color="text.primary"
          gutterBottom
          component="div"
          lineHeight={1.4}
          sx={{
            fontSize: '1rem',
          }}
        >
          {description && description}
        </Typography>
      </CardContent>
      <CardContent sx={{ padding: '4px 12px' }}>
        <Typography
          variant="body2"
          display="block"
          color="text.primary"
          gutterBottom
          component="div"
          lineHeight={1.4}
          sx={{
            fontSize: '1rem',
          }}
        >
          {descriptionLong && (
            <div dangerouslySetInnerHTML={{ __html: descriptionWithHash }} />
          )}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default TestDescriptionCard;
