import { useAuth0 } from '@auth0/auth0-react';
import Bugsnag, { NotifiableError } from '@bugsnag/js';
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import {
  useGetCompanyDetailsByCompanyIdQuery,
  useGetOnboardingQuestionsQuery,
  useInsertOnboardingResponsesMutation,
  useUpdateUserByUserIdMutation,
} from '../../@generated/facadeClient';
import { EAccountTypes, getAccountTypeId } from '../../constants/accounts';
import { VALID_LINKEDIN_REG_EXP } from '../../constants/regexp';
import { getCompanyId, getUserMetaData, isCandidate } from '../../utils/auth';
import { createStripeCustomer, startFreeTrial } from '../../utils/stripe';
import { AssessmentAlert } from '../AssessmentAlert';
import { ProgressLoader } from '../ProgressLoader';
import { EExploreNextSteps, onboardingSteps } from './data';
interface FormState {
  [key: number]: { answerId: number | null; other: string | null };
}
interface FormErrors {
  [key: number]: string;
}

export default function OnboardingPage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.only('xs'));

  const { user, getAccessTokenSilently } = useAuth0();
  const companyId = getCompanyId(user);

  const [openOnboarding, setOpenOnboarding] = useState(false);
  const [formState, setFormState] = useState<FormState>({});
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [linkedin, setLinkedIn] = useState('');
  const [linkedinError, setLinkedInError] = useState('');

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const accountType = getUserMetaData(user).account_type;

  const accountTypeId = getAccountTypeId(accountType);
  const stepsForAccount =
    accountType === EAccountTypes.personal
      ? onboardingSteps[EAccountTypes.personal]
      : onboardingSteps[EAccountTypes.company];

  const nextSteps =
    accountType === EAccountTypes.personal
      ? EExploreNextSteps.Personal
      : EExploreNextSteps.Company;

  const { data: companyData, loading: companyLoading } =
    useGetCompanyDetailsByCompanyIdQuery({
      skip: isCandidate(user),
      variables: {
        company_id: companyId,
      },
    });

  const showOnboarding = useMemo(
    () =>
      !companyLoading &&
      !!companyData &&
      companyData.companies.length > 0 &&
      !companyData.companies[0]['stripe_customer_id'],
    [companyData, companyLoading]
  );

  useEffect(() => {
    if (showOnboarding) {
      document.body.style.overflow = 'hidden';
      setOpenOnboarding(true);
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [showOnboarding]);

  const [insertOnboardingResponses] = useInsertOnboardingResponsesMutation();
  const [updateUserByUserIdMutation] = useUpdateUserByUserIdMutation();

  const { data: onboardingData, loading: onboardingLoading } =
    useGetOnboardingQuestionsQuery({
      variables: {
        account_type_id: accountTypeId,
      },
      skip: !openOnboarding,
    });

  const handleSelectChange = (questionId: number, value: string) => {
    const selectedAnswer = onboardingData?.onboarding_questions
      .find((question) => question.id === questionId)
      ?.onboarding_answers.find((answer) => answer.id === parseInt(value, 10));

    setFormState((prevState) => ({
      ...prevState,
      [questionId]: {
        ...prevState[questionId],
        answerId: selectedAnswer ? selectedAnswer.id : null,
        other: selectedAnswer?.is_other
          ? prevState[questionId]?.other || ''
          : null,
      },
    }));

    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [questionId]: '',
    }));
  };

  const handleOtherChange = (questionId: number, value: string) => {
    setFormState((prevState) => ({
      ...prevState,
      [questionId]: {
        ...prevState[questionId],
        other: value,
      },
    }));
  };

  const handleLinkedInChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setLinkedIn(value);

    if (value && !VALID_LINKEDIN_REG_EXP.test(value)) {
      setLinkedInError('Please enter a valid LinkedIn profile URL.');
    } else {
      setLinkedInError('');
    }
  };
  const { isLoading, mutate } = useMutation(
    async () => {
      try {
        const token = await getAccessTokenSilently();
        const userId = getUserMetaData(user).local_user_id;
        const responses = Object.entries(formState).map(
          ([questionId, { answerId, other }]) => ({
            question_id: parseInt(questionId, 10),
            answer_id: answerId,
            user_id: userId,
            other_answer: other || null,
          })
        );

        if (linkedin) {
          await updateUserByUserIdMutation({
            variables: {
              user_id: userId,
              set: {
                linkedin: linkedin,
              },
            },
          });
        }

        await insertOnboardingResponses({
          variables: {
            objects: responses,
          },
        });

        const { name, id } = companyData?.companies?.[0] ?? {};

        if (name && user?.email) {
          await createStripeCustomer(token, name, user.email);
          await startFreeTrial(token, accountType, id || companyId);
        }
      } catch (err) {
        Bugsnag.notify(err as NotifiableError);
        throw err;
      }
    },
    {
      onSuccess: () => {
        setOpenOnboarding(false);
        window.location.reload();
      },
      onError: () => {
        setSnackbarOpen(true);
      },
    }
  );

  const validateForm = (): boolean => {
    const errors: FormErrors = {};
    let isValid = true;

    onboardingData?.onboarding_questions.forEach((question) => {
      const { id, onboarding_answers } = question;
      const answer = formState[id];

      if (!answer?.answerId) {
        isValid = false;
        errors[id] = 'Please select an option';
      } else if (
        onboarding_answers.find(
          (a) => a.is_other && a.id === answer.answerId
        ) &&
        !answer.other
      ) {
        isValid = false;
        errors[id] = 'Please provide details for your selection';
      } else {
        errors[id] = '';
      }
    });

    setFormErrors(errors);
    if (
      accountType === EAccountTypes.personal &&
      linkedin &&
      !VALID_LINKEDIN_REG_EXP.test(linkedin)
    ) {
      console.log('invalid a');
      setLinkedInError('Please enter a valid LinkedIn profile URL.');
      isValid = false;
    }
    return isValid;
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!validateForm()) return;
    mutate();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const shouldShowLeftSection =
    accountType === EAccountTypes.company ||
    accountType !== EAccountTypes.personal ||
    !isMobile;

  return (
    <>
      {openOnboarding && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100vh',
            width: '100vw',
            position: 'fixed',
            top: 0,
            left: 0,
            bgcolor: 'background.default',
            zIndex: 1300,
            overflow: 'hidden',
            p: 0,
          }}
        >
          {(companyLoading || onboardingLoading) && <ProgressLoader />}
          <Grid container sx={{ height: '100vh', overflow: 'auto' }}>
            {shouldShowLeftSection && (
              <Grid
                item
                xs={12}
                md={6.5}
                sx={{
                  bgcolor: 'neutral.900',
                  color: 'white',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  p: 4,
                  overflow: 'auto',
                }}
              >
                <Typography
                  variant="h3"
                  fontWeight="bold"
                  textAlign="center"
                  gutterBottom
                  color={'neutral.200'}
                  sx={{ pb: 3 }}
                >
                  Welcome to Brokee!
                </Typography>
                <List sx={{ pl: 3, width: '100%' }}>
                  {stepsForAccount.map((step, index) => (
                    <ListItem key={index}>
                      <ListItemIcon>{step.icon}</ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography
                            variant="subtitle1"
                            fontWeight="bold"
                            sx={{ color: 'text', fontSize: '1.1rem' }}
                          >
                            {step.title}
                          </Typography>
                        }
                        secondary={
                          <Typography
                            variant="subtitle2"
                            sx={{ color: 'text.secondary', fontSize: '0.9rem' }}
                          >
                            {step.description}
                          </Typography>
                        }
                      />
                    </ListItem>
                  ))}
                </List>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  textAlign="center"
                  gutterBottom
                  color={'neutral.200'}
                  sx={{ pl: 5, m: 3, width: '100%', fontSize: '1.2rem' }}
                >
                  What’s Next?
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontWeight="bold"
                  textAlign="left"
                  gutterBottom
                  sx={{
                    pl: 5,
                    width: '100%',
                    fontSize: '0.9rem',
                    color: 'text.secondary',
                  }}
                >
                  {nextSteps}
                </Typography>
              </Grid>
            )}
            <Grid
              item
              xs={12}
              md={5.5}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 4,
                bgcolor: 'background.paper',
                overflow: 'auto',
              }}
            >
              <Paper
                elevation={0}
                sx={{
                  width: '100%',
                  maxWidth: '500px',
                  padding: 5,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  overflow: 'auto',
                }}
              >
                <Typography variant="h5" sx={{ mb: 3 }} gutterBottom>
                  Help Us Know You Better!
                </Typography>
                <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                  {onboardingData?.onboarding_questions.map((question) => (
                    <FormControl
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      key={question.id}
                      sx={{ mb: 2 }}
                    >
                      <Typography variant="subtitle1" sx={{ m: 1 }}>
                        {question.question}
                      </Typography>
                      <Select
                        labelId={`label-${question.id}`}
                        placeholder={'select'}
                        value={formState[question.id]?.answerId || ''}
                        onChange={(event) =>
                          handleSelectChange(
                            question.id,
                            String(event.target.value)
                          )
                        }
                        renderValue={(selected) => {
                          if (!selected) {
                            return 'Choose an option';
                          }
                          return (
                            question.onboarding_answers.find(
                              (answer) => answer.id === selected
                            )?.answer || ''
                          );
                        }}
                        displayEmpty
                      >
                        {question.onboarding_answers.map((answer) => (
                          <MenuItem key={answer.id} value={answer.id}>
                            {answer.answer}
                          </MenuItem>
                        ))}
                      </Select>
                      {formErrors[question.id] && (
                        <Typography color="error" variant="caption">
                          {formErrors[question.id]}
                        </Typography>
                      )}
                      {formState[question.id]?.answerId !== null &&
                        question.onboarding_answers.find(
                          (answer) => answer.is_other
                        )?.id === formState[question.id]?.answerId && (
                          <TextField
                            label="Please specify ..."
                            value={formState[question.id]?.other || ''}
                            onChange={(event) =>
                              handleOtherChange(question.id, event.target.value)
                            }
                            margin="normal"
                            sx={{ mt: 2 }}
                            fullWidth
                          />
                        )}
                    </FormControl>
                  ))}
                  {accountType === EAccountTypes.personal && (
                    <FormControl fullWidth margin="normal" variant="outlined">
                      <Typography variant="subtitle1" sx={{ m: 1 }}>
                        LinkedIn Profile (Optional)
                      </Typography>
                      <TextField
                        label="Share your LinkedIn profile if you’d like"
                        value={linkedin}
                        onChange={handleLinkedInChange}
                        margin="normal"
                        fullWidth
                        error={Boolean(linkedinError)}
                        helperText={linkedinError}
                        sx={{ m: 0 }}
                      />
                    </FormControl>
                  )}
                  <Button
                    id="start-free-trial"
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                    sx={{ mt: 4 }}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      'Start Free Trial'
                    )}
                  </Button>
                </form>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      )}
      <AssessmentAlert open={snackbarOpen} onClose={handleSnackbarClose} />
    </>
  );
}
