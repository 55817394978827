import { Button, Grid, LinearProgress } from '@mui/material';
import { Stack } from '@mui/system';
import { FC } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  useGetPersonPrivateTestByIdQuery,
  useGetPrivateTestByIdQuery,
} from '../../../@generated/facadeClient';
import CourseDescription from './CourseDescription';

import { useAuth0 } from '@auth0/auth0-react';
import { RocketLaunch } from '@mui/icons-material';
import { useBilling } from '../../../hooks/useBilling';
import { isPersonal } from '../../../utils/auth';
import { TestPropsDetails } from '../interfaces';
import CourseDetails from './TestCourseDetails';
import { BackNavigationButton } from './UIComponents/StyledComonents';
import { useHandleLaunchTest } from './data';

const TestCatalogueDetailPage: FC = () => {
  const { testId } = useParams();
  const location = useLocation();
  const { state } = location;
  const { user } = useAuth0();

  const { hasNoAccess } = useBilling();
  const handleLaunchTest = useHandleLaunchTest();

  const {
    data: { tests: privateTests } = { tests: [] },
    loading: testsLoading,
  } = useGetPrivateTestByIdQuery({
    variables: {
      brokee_id: Number(testId),
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: isPersonal(user),
  });

  const {
    data: { tests: userTests } = { tests: [] },
    loading: userTestsLoading,
  } = useGetPersonPrivateTestByIdQuery({
    variables: {
      brokee_id: Number(testId),
    },
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
    skip: !isPersonal(user),
  });

  const launchTest = () => {
    handleLaunchTest(Number(testId));
  };

  const tests = !isPersonal(user) ? privateTests : userTests;

  const loading = isPersonal(user) ? userTestsLoading : testsLoading;

  const test = tests[0];

  if (loading) {
    return (
      <>
        <LinearProgress
          sx={{
            width: '100%',
          }}
        />
      </>
    );
  }
  return (
    <Stack sx={{ width: '100%' }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <BackNavigationButton
          state={{
            filtersParams: state?.filters,
            page: state?.page,
            limit: state?.limit,
          }}
          navigationName="Test Catalogue"
          navigationPath="/company/tests-catalogue/"
        />
        <Button
          id="test-catalog-launch-test-button-top"
          disabled={hasNoAccess()}
          variant="contained"
          startIcon={<RocketLaunch />}
          onClick={launchTest}
          sx={{
            '@media (max-width:600px)': {
              marginBottom: '1.5rem',
            },
          }}
        >
          Launch Test
        </Button>
      </Grid>
      <Grid
        container
        justifyContent="center"
        alignItems="stretch"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          padding: '2rem',
          minHeight: 'compute(100vh - 64px)',
          backgroundColor: 'white',
          width: '100%',
          borderRadius: '15px',
        }}
      >
        <CourseDetails
          average_passed_time={test.test_stat?.average_passed_time || 0}
          passRate={test.test_stat?.pass_rate || 0}
          name={test.name}
          descriptionHuman={test?.description_human}
          testCategory={test?.test_category?.name ?? ''}
          testDifficulty={test?.test_difficulty?.level ?? ''}
          techRoles={test.test_tech_roles ?? []}
          techServices={test?.test_tech_services ?? []}
          testIssues={
            'test_issues' in test
              ? (test?.test_issues as TestPropsDetails['tests'][0]['test_issues'])
              : undefined
          }
          testResources={test?.test_tech_services_resources ?? []}
        />
        <CourseDescription
          testId={Number(testId)}
          title={test['name'] || ''}
          description={test['description_human'] || ''}
          descriptionLong={test['description_long'] || ''}
          image={test['arch_diagram_image'] || ''}
          testResources={test?.test_tech_services_resources ?? []}
        />
      </Grid>
    </Stack>
  );
};
export default TestCatalogueDetailPage;
