import SwipeLeftAltIcon from '@mui/icons-material/SwipeLeftAlt';
import { Divider, IconButton, List, Theme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { CSSObject, styled } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import { ASSESSMENT_DRAWER_WIDTH } from '../../constants/sizes';
import { Logo } from '../Logo';
import NavItem, { INavItemProps } from './NavItem';

const openedMixin = (theme: Theme): CSSObject => ({
  width: ASSESSMENT_DRAWER_WIDTH,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  color: theme.palette.common.white,
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(8)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  height: '70px',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: ASSESSMENT_DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

export default function Sidebar({
  open,
  onClose,
  menuItems,
}: {
  open: boolean;
  onClose(): void;
  menuItems: INavItemProps[];
}) {
  const { id } = useParams<{ id: string }>();

  const content = (
    <List
      id="assessment-sidebar"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'neutral.900',
        color: 'common.white',
      }}
    >
      {menuItems
        .filter((item) => item.visible !== false)
        .map((item) => (
          <NavItem
            key={item.title}
            href={id ? item.href.replace(':id', id) : item.href}
            icon={item.icon}
            title={item.title}
            visible={item.visible}
            open={open}
          />
        ))}
    </List>
  );

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader
        sx={{
          backgroundColor: 'neutral.900',
        }}
      >
        <Logo size="long" sx={{ pl: 2 }} />
        <IconButton
          id="assessment-page-hide-side-bar-menu"
          onClick={onClose}
          sx={{ backgroundColor: 'neutral.800' }}
        >
          {<SwipeLeftAltIcon color="primary" />}
        </IconButton>
      </DrawerHeader>
      <Divider sx={{ borderColor: 'neutral.700' }} />
      {content}
    </Drawer>
  );
}
