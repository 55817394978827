import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export interface INavItemProps {
  href: string;
  icon: ReactNode;
  title: string;
  visible: boolean;
  disabled?: boolean;
  open?: boolean;
}

export default function NavItem({
  href,
  icon,
  title,
  visible,
  open = false,
  disabled = false,
}: INavItemProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isRouteOrSubRouteActive = href ? pathname.endsWith(href) : false;

  const handleNavigate = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    navigate(href);
  };

  return (
    <ListItem key={title} disablePadding sx={{ display: 'block' }}>
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: open ? 'initial' : 'center',
          px: 3.25,
          py: 1.25,
        }}
        component="a"
        href={href}
        onClick={handleNavigate}
        disabled={disabled}
        id={`assessment-sidebar-menu-${title.replace(' ', '-')}`}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: open ? 2 : 'auto',
            justifyContent: 'center',
            color: isRouteOrSubRouteActive ? 'primary.main' : 'inherit',
          }}
        >
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            variant: 'subtitle2',
            fontSize: 14,
            color: isRouteOrSubRouteActive ? 'primary.main' : 'inherit',
            sx: {
              opacity: open ? 1 : 0,
              ml: 1,
              flexShrink: 0,
              fontWeight: 'bold',
            },
          }}
        />
      </ListItemButton>
    </ListItem>
  );
}
