import { useAuth0 } from '@auth0/auth0-react';
import { HelpOutline } from '@mui/icons-material';
import {
  Box,
  Grid,
  IconButton,
  Tabs,
  Tooltip,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useMemo } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import {
  ETourNames,
  tourStepsCompanySettings,
  tourStepsPersonalSettings,
} from '../../components/Onboarding/tour';
import { useTourContext } from '../../components/Onboarding/OnboardingTourContext';
import { TabPanel } from '../../components/Tabpanel/TabPanel';
import { useBilling } from '../../hooks/useBilling';
import { isPersonal } from '../../utils/auth';
import { BillingTab } from './BillingTab';
import { ESettingsTabs, tabs } from './data';
import InterviewSettingsTab from './InterviewSettingsTab';
import SubscriptionTab from './SubscriptionTab';
import TabLink from './TabLink';
import { TeamTab } from './TeamTab';

export default function SettingsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { canAccessProFeatures } = useBilling();
  const { user } = useAuth0();
  const { startTour } = useTourContext();
  const tourSteps = isPersonal(user)
    ? tourStepsPersonalSettings
    : tourStepsCompanySettings;

  const path = useMemo(
    () => location.pathname.split('/').pop() || '',
    [location]
  );

  const value = useMemo(
    () => (tabs.includes(path) ? path : ESettingsTabs.subscription),
    [path]
  );

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    navigate(newValue);
  };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <Typography
          variant="h3"
          display="block"
          gutterBottom
          sx={{ mt: '1rem', wordBreak: 'break-word' }}
        >
          Settings
          <Tooltip title="Explore settings with step-by-step guidance.">
            <IconButton
              size="small"
              color="inherit"
              onClick={() => startTour(ETourNames.settings, tourSteps)}
            >
              <HelpOutline />
            </IconButton>
          </Tooltip>
        </Typography>
      </Box>
      <Grid container sx={{ mt: '0.5rem' }}>
        <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange}>
            <TabLink
              label="Subscription"
              value={ESettingsTabs.subscription}
              href={ESettingsTabs.subscription}
            />
            <TabLink
              label="Billing"
              value={ESettingsTabs.billing}
              href={ESettingsTabs.billing}
            />
            {!isPersonal(user) && (
              <TabLink
                label="Team"
                value={ESettingsTabs.team}
                href={ESettingsTabs.team}
              />
            )}
            {!isPersonal(user) && canAccessProFeatures() && (
              <TabLink
                label="Dashboard"
                value={ESettingsTabs.dashboard}
                href={ESettingsTabs.dashboard}
              />
            )}
          </Tabs>
        </Grid>

        <Routes>
          <Route
            path="/"
            element={<Navigate to={ESettingsTabs.subscription} replace />}
          />
          <Route
            path={`/${ESettingsTabs.subscription}`}
            element={
              <TabPanel
                value={value}
                index={ESettingsTabs.subscription}
                sx={{ width: '100%' }}
              >
                <SubscriptionTab />
              </TabPanel>
            }
          />
          <Route
            path={`/${ESettingsTabs.billing}`}
            element={
              <TabPanel
                value={value}
                index={ESettingsTabs.billing}
                sx={{ width: '100%' }}
              >
                <BillingTab />
              </TabPanel>
            }
          />
          <Route
            path={`/${ESettingsTabs.team}`}
            element={
              <TabPanel
                value={value}
                index={ESettingsTabs.team}
                sx={{ width: '100%' }}
              >
                <TeamTab />
              </TabPanel>
            }
          />
          {canAccessProFeatures() && (
            <Route
              path={`/${ESettingsTabs.dashboard}`}
              element={
                <TabPanel value={value} index={ESettingsTabs.dashboard}>
                  <InterviewSettingsTab />
                </TabPanel>
              }
            />
          )}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Grid>
    </>
  );
}
