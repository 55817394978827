import Joyride, { ACTIONS, CallBackProps, EVENTS } from 'react-joyride';
import { useNavigate } from 'react-router-dom';

import { useTourContext } from './OnboardingTourContext';
import { joyrideLocale, joyrideStyle } from './data';

export default function OnboardingTourWrapper() {
  const navigate = useNavigate();
  const {
    run,
    currentTour,
    steps,
    stepIndex,
    isTourInitialized,
    setTriggerDialog,
    setTourInitialized,
    setRun,
    setStepIndex,
    stopTour,
  } = useTourContext();

  if (!run || !currentTour) return null;
  console.log('Tour Run:', run);

  const waitForElement = (selector: string, timeout = 5000): Promise<void> => {
    return new Promise((resolve, reject) => {
      const startTime = Date.now();

      const checkExistence = () => {
        const element = document.querySelector(selector);
        if (element) {
          resolve();
        } else if (Date.now() - startTime > timeout) {
          reject(
            new Error(
              `Element with selector "${selector}" not found within ${timeout}ms`
            )
          );
        } else {
          requestAnimationFrame(checkExistence);
        }
      };

      checkExistence();
    });
  };

  const handleCallback = async (data: CallBackProps) => {
    const { action, step, type, index } = data;
    const isPreviousAction = action === ACTIONS.PREV;

    if (type === EVENTS.TOUR_END || action === ACTIONS.CLOSE) {
      stopTour();
    }

    const { next, previous, openDialog, closeDialogOnNavigate } =
      step?.data || {};
    const nextStep = steps[currentTour][index + (isPreviousAction ? -1 : 1)];
    console.log('step', step, type, closeDialogOnNavigate);

    const handleAwaitForElement = async (target: string | HTMLElement) => {
      if (target) {
        try {
          setRun(false);
          await waitForElement(target as string);
          setRun(true);
        } catch (error) {
          console.error(
            `Element for step ${index + 1} not found. Skipping this step.`,
            error
          );
          setStepIndex(index + (isPreviousAction ? -1 : 1));
          setRun(false);
        }
      } else {
        console.warn(
          'Next step target is undefined or invalid. Skipping step.'
        );
        // setStepIndex(index + 1); skip id missing element
        setStepIndex(index + (isPreviousAction ? -1 : 1));
        setRun(false);
      }
    };

    if (action === ACTIONS.START && !isTourInitialized) {
      setTourInitialized(true);
      if (previous) {
        navigate(previous);
      }
      if (nextStep?.target) {
        await handleAwaitForElement(nextStep.target);
      }
    } else if (type === EVENTS.STEP_AFTER) {
      setStepIndex(index + (action === 'prev' ? -1 : 1));

      if (openDialog !== nextStep?.data?.openDialog) {
        // Change dialog state only if it differs between steps
        setTriggerDialog(nextStep?.data?.openDialog || false);
      }

      // if (!openDialog && closeDialogOnNavigate) {
      //   setTriggerDialog(true);
      // } else {
      //   setTriggerDialog(false);
      // }

      if (isPreviousAction && previous) {
        navigate(previous);
      } else if (!isPreviousAction && next) {
        navigate(next);
      }
      // waiting for element, skip the ste if target element not found
      if (nextStep?.target) {
        await handleAwaitForElement(nextStep.target);
      }
    }
  };

  return (
    <Joyride
      steps={steps[currentTour] || []}
      run={run}
      stepIndex={stepIndex}
      continuous={true}
      showSkipButton={true}
      showProgress={true}
      scrollToFirstStep={true}
      callback={handleCallback}
      locale={joyrideLocale}
      styles={joyrideStyle}
    />
  );
}
