import { DEMO_USER_ID } from '../../../constants/accounts';

export const defaultLimit = 10;

export const defaultPage = 0;

export const defaultFilters = {
  deleted_at: { _is_null: true },
  user_id: { _neq: DEMO_USER_ID }, // hide demo user
};

export interface ISortCriteria {
  key: ESortByKeys;
  order: 'asc' | 'desc';
}

export enum ESortByKeys {
  invitedAtUp = 'invitedAtUp',
  invitedAtDown = 'invitedAtDown',
  loggedAtUp = 'loggedAtUp',
  loggedAtDown = 'loggedAtDown',
  emailUp = 'emailUp',
  emailDown = 'emailDown',
  timeUp = 'timeUp',
  timeDown = 'timeDown',
  competitionScoreUp = 'competitionScoreUp',
  competitionScoreDown = 'competitionScoreDown',
}
type SortOrder = {
  [key: string]: string | { [nestedKey: string]: string };
};

export const sortByMap: { [key: string]: SortOrder } = {
  [ESortByKeys.invitedAtUp]: {
    invited_at: 'asc_nulls_last',
  },
  [ESortByKeys.invitedAtDown]: {
    invited_at: 'desc_nulls_last',
  },
  [ESortByKeys.loggedAtUp]: {
    candidate: { logged_at: 'asc_nulls_last' },
    invited_at: 'desc_nulls_last',
  },
  [ESortByKeys.loggedAtDown]: {
    candidate: { logged_at: 'desc_nulls_last' },
    invited_at: 'asc_nulls_last',
  },
  [ESortByKeys.emailUp]: {
    candidate: {
      email: 'asc',
    },
  },
  [ESortByKeys.emailDown]: {
    candidate: {
      email: 'desc',
    },
  },
  [ESortByKeys.timeUp]: {
    time_taken_seconds: 'desc_nulls_last',
  },
  [ESortByKeys.timeDown]: {
    time_taken_seconds: 'asc_nulls_last',
  },
  [ESortByKeys.competitionScoreUp]: {
    completion_score: 'asc_nulls_last',
  },
  [ESortByKeys.competitionScoreDown]: {
    completion_score: 'desc_nulls_last',
  },
};

export const sortBy = [
  {
    value: ESortByKeys.invitedAtDown,
    label: 'Invited At (Newest First)',
  },
  {
    value: ESortByKeys.invitedAtUp,
    label: 'Invited At (Oldest First)',
  },
  {
    value: ESortByKeys.loggedAtDown,
    label: 'Last Login (Recent First)',
  },
  {
    value: ESortByKeys.loggedAtUp,
    label: 'Last Login (Oldest First)',
  },
  {
    value: ESortByKeys.competitionScoreDown,
    label: 'Competition Score (Highest First)',
  },
  {
    value: ESortByKeys.competitionScoreUp,
    label: 'Competition Score (Lowest First)',
  },
  {
    value: ESortByKeys.timeUp,
    label: 'Time taken  (Longest First)',
  },
  {
    value: ESortByKeys.timeDown,
    label: 'Time taken (Shortest First)',
  },
  {
    value: ESortByKeys.emailUp,
    label: 'Email (A-Z)',
  },
  {
    value: ESortByKeys.emailDown,
    label: 'Email (Z-A)',
  },
];

export enum EFilterByResultKeys {
  all = 'all',
  passed = 'passed',
  failed = 'failed',
  none = 'none',
}

export const filterByResultMaps = {
  [EFilterByResultKeys.all]: {},
  [EFilterByResultKeys.passed]: { passed: { _eq: true } },
  [EFilterByResultKeys.failed]: { passed: { _eq: false } },
  [EFilterByResultKeys.none]: { passed: { _is_null: true } },
};

export const filterByTestResult = [
  {
    value: EFilterByResultKeys.all,
    label: 'All',
  },
  {
    value: EFilterByResultKeys.passed,
    label: 'Passed',
  },
  {
    value: EFilterByResultKeys.failed,
    label: 'Failed',
  },
  {
    value: EFilterByResultKeys.none,
    label: 'Pending',
  },
];

export const defaultSortCriteria: ISortCriteria[] = [
  { key: ESortByKeys.loggedAtDown, order: 'desc' },
];

export const parseSortParams = (sortParams: string): ISortCriteria[] => {
  return sortParams.split('&').map((param) => {
    const key = decodeURIComponent(param) as ESortByKeys;
    const order = key.endsWith('Up') ? 'asc' : 'desc';
    return { key, order };
  });
};

export const getSortParameters = (
  sortCriteria: ISortCriteria[]
): Record<string, any> => {
  return sortCriteria.reduce((acc: Record<string, any>, criteria) => {
    const sortMap = sortByMap[criteria.key];
    if (sortMap) {
      Object.entries(sortMap).forEach(([nestedKey, order]) => {
        acc[nestedKey] = order;
      });
    }
    return acc;
  }, {});
};
